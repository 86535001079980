import { useCallback } from "react";
import TableWidget from "../../../../components/tables/TableWidget";
import useRoutesHelper from "../../../../hooks/useRoutesHelper";
import { fetchFleetFeatureDocuments } from "../../../../redux/api/fleet.api";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { clearFleetFeatureDocuments } from "../../../../redux/reducer/fleet.reducer";

import {
  fleetFeatureDocumentsTableHeader,
  geFleetFeatureDocumentsTableData,
} from "./components/tableConfigs/fleetFeatureDocumentsTableConfig";

import FleetFeatureDocumentsActionButton from "./components/TableActionsButtonsComponents/FleetFeatureDocumentsActionButton";
import TableCustomHeader from "./components/TableCustomHeader";

function FleetFeatureDocuments() {
  const { routeMainParam } = useRoutesHelper();

  const { carId, featureId } = routeMainParam as {
    carId: string;
    featureId: string;
  };

  const dispatch = useAppDispatch();

  const {
    fleet: {
      fleetFeatureDocuments: {
        data: fleetFeatureDocumentsData,
        isLoading: isLoadingFleet,
      },
    },
  } = useAppSelector((state) => ({
    fleet: state.fleet,
  }));

  const handleTableActions = () => {
    if (carId) {
      dispatch(fetchFleetFeatureDocuments({ id: featureId }));
    }
  };

  const handleUnmount = useCallback(() => {
    dispatch(clearFleetFeatureDocuments());
  }, [dispatch]);

  return (
    <TableWidget
      CustomHeaderComponent={TableCustomHeader}
      tableHeaderData={fleetFeatureDocumentsTableHeader}
      tableItemsData={geFleetFeatureDocumentsTableData(
        fleetFeatureDocumentsData,
      )}
      handleTableActions={handleTableActions}
      handleUnmount={handleUnmount}
      borderedRow
      tableLoading={isLoadingFleet}
      ActionButtonsComponent={FleetFeatureDocumentsActionButton}
      showSearchForCategories={false}
      withTablePagination={false}
    />
  );
}

export default FleetFeatureDocuments;
