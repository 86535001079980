import { useEffect, useState } from "react";

export const useDebounce = (
  value: string | undefined | number | null,
  delay: number,
) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<string | number>("");
  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      if (value !== undefined && value !== null) {
        setDebouncedValue(value);
      }
    }, delay);
    // Cancel the timeout if value changes (also on delay change or unmount)
    // This is how we prevent debounced value from updating if value is changed ...
    // .. within the delay period. Timeout gets cleared and restarted.
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};
