import CustomButton from "../../../../components/CustomButton";
import { useAppDispatch } from "../../../../redux/hooks";
import { setClientSelectedWorkingPoint } from "../../../../redux/reducer/expedition.reducer";

function ClientWorkingPointsActionButton({ crudData }: any) {
  const dispatch = useAppDispatch();

  const handleButtonPress = () => {
    dispatch(setClientSelectedWorkingPoint(crudData));
  };

  return (
    <div>
      <CustomButton variant="contained" onClick={handleButtonPress}>
        Asociere
      </CustomButton>
    </div>
  );
}

export default ClientWorkingPointsActionButton;
