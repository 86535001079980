import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";

import ContentCard from "../../../components/misc/ContentCard";
import CustomButton from "../../../components/CustomButton";
import TextInput from "../../../components/misc/TextInput";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import useRoutesHelper from "../../../hooks/useRoutesHelper";

import {
  fetchUserDetails,
  postUserDetails,
} from "../../../redux/api/admin.api";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../helpers/apiHelper";
import Dropdown, { DropdownValue } from "../../../components/misc/Dropdown";
import { getWorkingRoutesNodes } from "../../../redux/api/expedition.api";

interface initialValuesInterface {
  userName: string;
  firstName: string;
  lastName: string;
  password: string;
  verifyPassword: string;
  userFunction: number | null;
  node: number | null;
}

const initialValues: initialValuesInterface = {
  userName: "",
  firstName: "",
  lastName: "",
  password: "",
  verifyPassword: "",
  userFunction: null,
  node: null,
};

const productSchema = Yup.object({
  userName: Yup.string().required("Campul este obligatoriu"),
  firstName: Yup.string().required("Campul este obligatoriu"),
  lastName: Yup.string().required("Campul este obligatoriu"),
  password: Yup.string(),
  verifyPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Parolele nu se potrivesc",
  ),
});

const userFunctionsDropdownValue = [
  { id: 10, label: "Livrator" },
  { id: 11, label: "Sofer internod" },
  { id: 12, label: "Manager nod (ierarhic direct pentru livratori si soferi)" },
  { id: 13, label: "Dispecer logistica" },
  { id: 14, label: "Manager logistica (ierarhic direct pt manager nod)" },
  { id: 15, label: "Persoana responsabila farmacie" },
  { id: 16, label: "Manager general" },
  { id: 77, label: "Superuser" },
];

function EditUser() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { routeParams } = useRoutesHelper();

  const userId = routeParams.userId;

  const {
    admin: {
      user: { data: userDetailsData, isLoading: isLoadingUser },
    },
    expedition: {
      workingRoutesNodes: {
        data: { data: userAllNodes },
      },
    },
  } = useAppSelector((state) => ({
    admin: state.admin,
    expedition: state.expedition,
  }));

  const [formikValues, setFormikValues] =
    useState<initialValuesInterface>(initialValues);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedUserFunction, setSelectedUserFunction] =
    useState<DropdownValue | null>(null);
  const [selectedUserNode, setSelectedUserNode] =
    useState<DropdownValue | null>(null);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserDetails(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch(
      getWorkingRoutesNodes({
        page: 1,
        perPage: 1000,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (userDetailsData) {
      setFormikValues((value) => ({
        ...value,
        userName: userDetailsData?.username || "",
        firstName: userDetailsData?.nume || "",
        lastName: userDetailsData?.prenume || "",
      }));
    }
  }, [userDetailsData]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSaveProductDetails = async (value: initialValuesInterface) => {
    setIsLoading(true);

    const resultAction = await dispatch(
      postUserDetails({
        userId,
        userDetails: value,
        isUpdating: true,
      }),
    );

    if (
      checkEndpointStatus(resultAction, postUserDetails) !==
      endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, postUserDetails) ===
      endpointStatus.fulfilled
    ) {
      navigate(-1);
    }
  };
  const userAllNodesNew = useMemo(
    () =>
      userAllNodes.map((item) => {
        return {
          id: item.id_nod,
          label: item.nodNume,
        };
      }),
    [userAllNodes],
  );
  useEffect(() => {
    if (userDetailsData) {
      const currentUserFunction = userFunctionsDropdownValue.find(
        (userFunction) => userFunction.id === userDetailsData.user_role,
      );
      setSelectedUserFunction(currentUserFunction || null);

      const currentUserNode = userAllNodesNew.find(
        (userNode) => userNode.id === userDetailsData.user_dept,
      );

      setSelectedUserNode(currentUserNode || null);
    }
  }, [userAllNodesNew, userDetailsData]);

  return (
    <ContentCard cardTitle="Editeaza utilizator" isLoading={isLoadingUser}>
      <Formik
        enableReinitialize
        initialValues={formikValues}
        validationSchema={productSchema}
        onSubmit={handleSaveProductDetails}>
        {({
          isValid,
          errors,
          touched,
          getFieldProps,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => {
          return (
            <form
              className="form w-100"
              onSubmit={handleSubmit}
              noValidate
              id="kt_login_signin_form">
              <TextInput
                label="Username"
                name="userName"
                error={errors["userName"]}
                touched={touched["userName"]}
                inputProps={getFieldProps("userName")}
              />
              <TextInput
                label="Nume"
                name="lastName"
                error={errors["lastName"]}
                touched={touched["lastName"]}
                inputProps={getFieldProps("lastName")}
              />
              <TextInput
                label="Prenume"
                name="firstName"
                error={errors["firstName"]}
                touched={touched["firstName"]}
                inputProps={getFieldProps("firstName")}
              />
              <TextInput
                label="Parola"
                name="password"
                type="password"
                error={errors["password"]}
                touched={touched["password"]}
                inputProps={getFieldProps("password")}
              />
              <TextInput
                label="Verifica Parola"
                name="verifyPassword"
                type="password"
                error={errors["verifyPassword"]}
                touched={touched["verifyPassword"]}
                inputProps={getFieldProps("verifyPassword")}
              />
              <Dropdown
                className="mb-4 me-2"
                title="Selecteaza functia"
                data={userFunctionsDropdownValue}
                singleValue={selectedUserFunction}
                setSingleValue={(value) => {
                  setSelectedUserFunction(value);
                  setFieldValue("userFunction", value?.id);
                }}
              />

              <Dropdown
                className="my-4"
                title="Selecteaza nod"
                data={userAllNodesNew}
                singleValue={selectedUserNode}
                setSingleValue={(value) => {
                  setSelectedUserNode(value);
                  setFieldValue("node", value?.id);
                }}
              />

              <div className="d-flex justify-content-between">
                <CustomButton
                  type="submit"
                  variant="outlined"
                  onClick={handleGoBack}>
                  Anuleaza
                </CustomButton>
                <CustomButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting || isLoading}
                  disabled={!isValid || isSubmitting}>
                  Salveaza
                </CustomButton>
              </div>
            </form>
          );
        }}
      </Formik>
    </ContentCard>
  );
}

export default EditUser;
