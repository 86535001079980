import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchShowDocs } from "../../../redux/api/fleet.api";
import useRoutesHelper from "../../../hooks/useRoutesHelper";
import {
  fleetDocumentsTableHeader,
  getFleetDocumentsTableData,
} from "./components/tableConfigs/fleetDocumentsTableConfig";

import TableWidget from "../../../components/tables/TableWidget";
import { clearFleetDocuments } from "../../../redux/reducer/fleet.reducer";

import FleetDocumentsActionButton from "./components/TableActionsButtonsComponents/FleetDocumentsActionButton";
import TableCustomHeader from "./components/TableCustomHeader";

function FleetDocuments() {
  const { routeMainParam } = useRoutesHelper();

  const { carId } = routeMainParam as { carId: string; nrauto: string };

  const dispatch = useAppDispatch();

  const {
    fleet: {
      fleetDocuments: { data: fleetDocumentsData, isLoading: isLoadingFleet },
    },
  } = useAppSelector((state) => ({
    fleet: state.fleet,
  }));

  const handleTableActions = () => {
    if (carId) {
      dispatch(fetchShowDocs({ id: carId }));
    }
  };

  const handleUnmount = useCallback(() => {
    dispatch(clearFleetDocuments());
  }, [dispatch]);

  return (
    <TableWidget
      CustomHeaderComponent={TableCustomHeader}
      tableHeaderData={fleetDocumentsTableHeader}
      tableItemsData={getFleetDocumentsTableData(fleetDocumentsData)}
      handleTableActions={handleTableActions}
      handleUnmount={handleUnmount}
      borderedRow
      tableLoading={isLoadingFleet}
      ActionButtonsComponent={FleetDocumentsActionButton}
      showSearchForCategories={false}
      withTablePagination={false}
    />
  );
}

export default FleetDocuments;
