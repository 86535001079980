import {
  TableData,
  TableHeaderCategoryConfig,
} from "../../../../../components/tables/tableContext/TableContext";
import { FleetAllDocumentsDataInterface } from "../../../../../redux/interfaces/fleet.interface";
import { getDateAndTime } from "../../../../../utils/dateAndTime";

export const fleetAllDocumentsTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Nr. auto",
    value: "nr_auto",
    minW: 125,
  },
  {
    label: "Document",
    value: "documentType",
    minW: 125,
  },
  {
    label: "Observatii",
    value: "description",
    minW: 125,
  },
  {
    label: "Tip",
    value: "tip",
    minW: 125,
  },
  {
    label: "Valabilitate",
    value: "valability",
    minW: 125,
    withFilter: [
      {
        label: "Documente care expira curand",
        value: 1,
      },
      {
        label: "Documente active",
        value: 2,
      },
      {
        label: "Documente expirate",
        value: 3,
      },
    ],
  },
];

export const getFleetAllDocumentsTableData = (
  data: FleetAllDocumentsDataInterface[],
): TableData[] =>
  data.map((item) => {
    return {
      id: item.id_caldoc
        ? `echipament-${item.id_caldoc}`
        : `masina-${item.id_doc}`,
      crudData: item,
      data: [
        { title: item.nrauto },
        {
          title: item.denumire,
        },
        {
          title: item.caldoc_obs ?? item.doc_obs,
        },
        { title: item.id_caldoc ? "Document echipament" : "Document masina" },
        {
          badges: [
            {
              badgeText: getDateAndTime(item.doc_ts_start),
              badgeColor: "light-info",
            },
            {
              badgeText: getDateAndTime(item.doc_ts_stop),
              badgeColor: "light-danger",
            },
          ],
        },
      ],
    };
  });
