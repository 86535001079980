export default {
  // FLEET`
  PREVIEW_FLEET: "preview_fleet",
  CAR_CREATE: "create_fleet",
  CAR_EDIT: "edit_fleet",
  CAR_DELETE: "delete_fleet",

  CAR_DOCUMENTS_ALL_DISPLAY: "documents_all_display",
  CAR_DOCUMENTS_DISPLAY: "documents_display",
  CAR_DOCUMENT_CREATE: "documents_create",
  CAR_DOCUMENT_EDIT: "documents_edit",
  CAR_DOCUMENT_DELETE: "documents_delete",

  CAR_FEATURES_DISPLAY: "features_display",
  CAR_FEATURE_CREATE: "features_create",
  CAR_FEATURE_EDIT: "features_edit",
  CAR_FEATURE_DELETE: "features_delete",

  CAR_FEATURE_DOCUMENTS_DISPLAY: "feature_documents_display",
  CAR_FEATURE_DOCUMENT_CREATE: "feature_documents_create",
  CAR_FEATURE_DOCUMENT_EDIT: "feature_documents_edit",
  CAR_FEATURE_DOCUMENT_DELETE: "feature_documents_delete",

  // EXPEDITION
  SCHEDULE_WORKING_POINTS: "schedule_working_points",
  WORKING_POINTS_ORGANIZE: "working_points_organize",
  SCHEDULE_WORKING_ROUTE: "schedule_working_route",
  WORKING_ROUTES_ORGANIZE: "working_routes_organize",
  NODES: "nodes",
  EDIT_NODES_ROUTES: "edit_nodes_routes",
  CLIENTS: "clients",
  CLIENTS_WORKING_POINTS: "clients_working_points",
  // TRANSPORT
  ASSIGN_ROUTE: "assign_route",
  // REPORTS
  SEARCH_INVOICES_AWB: "search_invoices_awb",
  // ADMIN
  PREVIEW_USERS: "preview_users",
  CREATE_USER: "create_user",
  EDIT_USER: "edit_user",
  ADMINISTRATE_USER_ROLES: "administrate_user_web_roles",
  ADMINISTRATE_USER_MOBILE_ROLES: "administrate_user_mobile_roles",
};
