import React, { useState } from "react";
import { GlobalContext } from "./GlobalContext";

type Props = {
  children: React.ReactNode;
};

const GlobalProvider = ({ children }: Props) => {
  const [theme, setTheme] = useState(true);

  return (
    <GlobalContext.Provider
      value={{
        theme,
        setTheme,
      }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
