export default {
  mainColor: "#09CA9A",
  mainColor40Opacity: "#CCF8ED",
  blueSecondary: "#4FC2E7",
  blueLight: "#DDF6FF",
  blueMedium: "#A4E3FE",
  blueLighter: "#E8F7FE",
  blue: "#009EF7",
  darkBlue: "#1A324C",
  midDarkBlue: "#097FAF",
  red: "#FF3835",
  green: "#09CA9A",
  lime: "#00FFC6",
  purple: "#8335FF",
  white: "#FFFFFF",
  lightGrey: "#DEDEDE",
  darkGrey: "#9E9DA2",
  dark: "#282828",
  transparent: "rgba(52, 52, 52, 0.8)",
  backgroundPopup: "rgba(0, 0, 0, 0.4)",
  backgroundBlur: "rgba(255,255,255,0.9)",
  background: "#F9F9F9",
  lightOrange: "#FDBE53",
  lightYellow: "#FEF8ED",
  black: "#000000",
  orange: "#E66A3A",
  mauve: "#2B2737",
  cadetBlue: "#A7B3C0",
  turquoise: "#3BCEE9",

  greyScale: {
    grey1: "#F4F4F4",
    grey2: "#E8E8E8",
    grey3: "#B2B2B2",
    grey4: "#F9F9F9",
    grey5: "#DFE3E8",
    grey6: "#CCCCCC",
    grey7: "#C9C9C9",
    grey8: "#C0C8D0",
    grey9: "#EEEEF1",
    grey10: "#A7B3C0",
    grey11: "#9DA1B3",
    grey12: "#F6F6F8",
  },
};

export const hex2rgba = (hex: string, alpha = 1) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};
