import { useCallback } from "react";
import TableWidget from "../../../components/tables/TableWidget";

import { TableActions } from "../../../components/tables/tableContext/TableContext";
import { getClientsWorkingPoints } from "../../../redux/api/expedition.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { clearClientsWorkingPoints } from "../../../redux/reducer/expedition.reducer";

import {
  clientsWorkingPointsTableHeader,
  getClientsWorkingPointsTableData,
} from "./tableConfigs/clientsWorkingPointsTableConfig";
import useRoutesHelper from "../../../hooks/useRoutesHelper";
import ClientWorkingPointsActionButton from "./components/ClientWorkingPointsActionButton";
import ClientWorkingPointModal from "./components/ClientWorkingPointModal";

function ClientsWorkingPoints() {
  const dispatch = useAppDispatch();
  const { routeMainParam, routeParams } = useRoutesHelper();

  const { clientId } = routeMainParam as { clientId: string };
  const { denumireClient } = routeParams as { denumireClient: string };

  const {
    expedition: {
      clientsWorkingPoints: { data: clientsWorkingPointsData, isLoading },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const handleTableActions = useCallback(
    (action: TableActions) => {
      if (action.tablePagination.page && action.tablePagination.rowsPerPage) {
        dispatch(
          getClientsWorkingPoints({
            page: action.tablePagination.page,
            perPage: action.tablePagination.rowsPerPage,
            deliveryPoint: action.tableSearch?.deliveryPoint ?? "",
            clientId: clientId,
            adresa: action.tableSearch.adresa ?? "",
            localitate: action.tableSearch.localitate ?? "",
            judet: action.tableSearch.judet ?? "",
          }),
        );
      }
    },
    [clientId, dispatch],
  );

  const handleUnmount = useCallback(() => {
    dispatch(clearClientsWorkingPoints());
  }, [dispatch]);

  return (
    <>
      <ClientWorkingPointModal />
      <TableWidget
        tableTitle={`Puncte de livrare pentru clientul: ${denumireClient}`}
        tableHeaderData={clientsWorkingPointsTableHeader}
        tableItemsData={getClientsWorkingPointsTableData(
          clientsWorkingPointsData?.data,
        )}
        handleTableActions={handleTableActions}
        handleUnmount={handleUnmount}
        totalItems={clientsWorkingPointsData.total}
        tableLoading={isLoading}
        borderedRow
        ActionButtonsComponent={(props: any) => (
          <ClientWorkingPointsActionButton {...props} />
        )}
      />
    </>
  );
}

export default ClientsWorkingPoints;
