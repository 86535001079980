import CustomButton from "../../../../../components/CustomButton";
import SvgIcon from "../../../../../helpers/SvgIcon";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../../../helpers/apiHelper";
import { postDeleteUser } from "../../../../../redux/api/admin.api";
import { useAppDispatch } from "../../../../../redux/hooks";
import { removeUserFromUsers } from "../../../../../redux/reducer/admin.reducer";
import { adminRoutes } from "../../../../../router/routesConstants";

function UsersActionButton({ itemId, crudData, tableContext }: any) {
  const dispatch = useAppDispatch();
  const handleDeleteUser = async () => {
    const resultAction = await dispatch(postDeleteUser({ userId: itemId }));

    if (
      checkEndpointStatus(resultAction, postDeleteUser) ===
      endpointStatus.fulfilled
    ) {
      if (
        tableContext.tablePagination.page &&
        tableContext.tablePagination.rowsPerPage
      ) {
        dispatch(removeUserFromUsers(itemId));
      }
    }
  };

  return (
    <div>
      <CustomButton
        variant="contained"
        className="mt-1 me-1"
        navigate={`/${adminRoutes.users}/${adminRoutes.editUser}?userId=${itemId}`}>
        <SvgIcon type="EDIT" />
      </CustomButton>

      <CustomButton
        variant="contained"
        className="mt-1 me-1"
        navigate={`/${adminRoutes.users}/${adminRoutes.adminUserWebRoles}?userId=${itemId}&userName=${crudData.username}`}>
        Roluri web
      </CustomButton>
      <CustomButton
        variant="contained"
        className="mt-1 me-1"
        navigate={`/${adminRoutes.users}/${adminRoutes.adminUserMobileRoles}?userId=${itemId}&userName=${crudData.username}`}>
        Roluri palm
      </CustomButton>
      <CustomButton
        variant="contained"
        color="error"
        className="mt-1 me-1"
        withConfirmationModal={{
          modalTitle: "Stergere utilizator",
          modalSubtitle: `Doriti sa stergeti utilizatorul: ${crudData.username}?`,
          modalLeftButtonVariant: "outlined",
          modalRightButtonOnClick: handleDeleteUser,
        }}>
        Sterge
      </CustomButton>
    </div>
  );
}

export default UsersActionButton;
