import { createSlice } from '@reduxjs/toolkit';

interface InitialAppState {
  isAppLoading: boolean;
}

const initialAppState: InitialAppState = {
  isAppLoading: true,
};

const appConfig = createSlice({
  name: 'appConfig',
  initialState: initialAppState,
  reducers: {
    appWasLoaded: state => {
      state.isAppLoading = false;
    },
  },
});

export const { appWasLoaded } = appConfig.actions;

export default appConfig.reducer;
