import React from "react";

function SelectableChecker({
  type = "checkbox",
  name,
  value,
  checked = false,
  onChange,
  isDisabled,
  id,
}: {
  type?: "checkbox" | "radio";
  name: string;
  value?: string | number;
  checked?: boolean;
  onChange: (isChecked: boolean) => void;
  isDisabled?: boolean;
  id?: string;
}) {
  return (
    <span className="form-check form-check-custom form-check-solid">
      <input
        id={id}
        className="form-check-input"
        type={type}
        name={name}
        value={value}
        checked={checked}
        onChange={(event) => onChange(event.target.checked)}
        disabled={isDisabled}
      />
    </span>
  );
}

export default SelectableChecker;
