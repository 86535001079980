import {
  TableData,
  TableHeaderCategoryConfig,
} from "../../../../../components/tables/tableContext/TableContext";
import { FleetDocumentsDataInterface } from "../../../../../redux/interfaces/fleet.interface";
import {
  getDateAndTime,
  isBeforeToday,
} from "../../../../../utils/dateAndTime";

export const fleetDocumentsTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Document",
    value: "document",
    minW: 125,
  },
  {
    label: "Observatii",
    value: "description",
    minW: 125,
  },
  {
    label: "Valabilitate",
    value: "valability",
    minW: 125,
  },
];

export const getFleetDocumentsTableData = (
  data: FleetDocumentsDataInterface[],
): TableData[] =>
  data.map((item) => {
    const checkIfIsExpired = isBeforeToday(item.doc_ts_stop);

    return {
      id: item.id_doc,
      crudData: item,
      data: [
        {
          title: item.denumire,
        },
        {
          title: item.doc_obs,
        },
        {
          badges: [
            {
              badgeText: getDateAndTime(item.doc_ts_start),
              badgeColor: "light-info",
            },
            {
              badgeText: checkIfIsExpired
                ? "Expirat"
                : getDateAndTime(item.doc_ts_stop),
              badgeColor: checkIfIsExpired ? "danger" : "light-danger",
            },
          ],
        },
      ],
    };
  });
