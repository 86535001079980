import moment from "moment";

export const getDateFormat = (time: number | string) =>
  moment(time).format("DD MMM YYYY");

export const getFirstLetterUpperCase = (text: string | undefined) => {
  if (text && text.length > 0) {
    const array = text.split(" ");
    const result = array.map((str) => str.toLowerCase()).join(" ");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
  return "";
};

export const getFirstName = (fullName: string | undefined) => {
  // Fall back if there is no name at all
  if (!fullName) {
    return "Pal";
  }
  const names = fullName.split(" ");
  if (names.length === 1) {
    return fullName;
  }

  // Check if first name is just one letter e.g J Smith
  if (names[0].length === 1) {
    return `${names[0]} ${names[1]}`;
  }

  return names[0];
};

// export const parseGender = gender => {
//   if (!gender || gender === 'N/A') {
//     return '-';
//   }

//   const genders = {
//     male: strings.editProfileScreen_genders[0],
//     female: strings.editProfileScreen_genders[1],
//     other: strings.editProfileScreen_genders[2],
//   };

//   return genders[gender];
// };

export const sortRandomly = (arrayProp: any[]) => {
  const mixedOptions = [];
  const indexOfOptions: any[] = [];
  const length = arrayProp.length;
  if (length === 0) {
    console.warn("You send an empty array on sortRandomly function");
    return [];
  }
  while (indexOfOptions.length < length) {
    const number = Math.floor(Math.random() * length);
    const addNumber = indexOfOptions.every((item) => item !== number);

    if (addNumber) {
      indexOfOptions.push(number);
      mixedOptions.push(arrayProp[number]);
    }
  }
  return mixedOptions;
};

export const findKeyOfValue = (obj: any, value: any) =>
  Object.keys(obj).find((key) => obj[key] === value);

export const checkIfIsNumber = (text: string) => {
  const isNumber = /^\d+$/;
  return isNumber.test(text);
};

export const onlyFirstLetterUppercase = (text = "") =>
  text.charAt(0).toUpperCase() + text.slice(1);

export const getCurrency = (price: string | undefined) => {
  return price?.match(/[^\d,.""]/g)?.join("") ?? "";
};
export const isLessThanFiveMinutes = (
  dateAndTime: Date | string | undefined,
) => {
  if (!dateAndTime) {
    return false;
  }
  const now = moment(moment().utc().format());
  const date = moment(dateAndTime);
  const timeLeftMinute = moment.duration(now.diff(date)).minutes();
  return timeLeftMinute < 5;
};
