import { useCallback, useEffect, useMemo, useState } from "react";
import ModalList, {
  ModalListItem,
} from "../../../../components/misc/ModalList/ModalList";
import CustomButton from "../../../../components/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { ScheduleWorkingPointsListInterface } from "../../../../redux/interfaces/expedition.interface";
import {
  fetchUpdateWorkingPoint,
  getScheduleWorkingPoints,
} from "../../../../redux/api/expedition.api";
import useRoutesHelper from "../../../../hooks/useRoutesHelper";
import {
  clearScheduleWorkingPoints,
  setClientSelectedWorkingPoint,
} from "../../../../redux/reducer/expedition.reducer";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../../helpers/apiHelper";

function ClientWorkingPointModal() {
  const { routeMainParam } = useRoutesHelper();

  const { clientId } = routeMainParam as { clientId: string };

  const dispatch = useAppDispatch();

  const {
    expedition: {
      scheduleWorkingPoints: {
        data: { data: workingPoints },
      },
      clientSelectedWorkingPoint,
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const [selectedWorkingPoint, setSelectedWorkingPoint] =
    useState<ScheduleWorkingPointsListInterface | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const initialClientsWorkingPointsList: ModalListItem[] = useMemo(
    () =>
      workingPoints.map((item) => {
        const { id_punctlucru: id, denumire: label } = item;
        return {
          ...item,
          label: `${label}`, // required
          id: id, // required
          backgroundColor:
            id === selectedWorkingPoint?.id_punctlucru ? "warning" : "info",
        };
      }),
    [workingPoints, selectedWorkingPoint],
  );

  const workingPointModalActions = useCallback(
    ({ search }: { search: string }) => {
      dispatch(
        getScheduleWorkingPoints({
          page: 1,
          perPage: 50,
          deliveryPoint: search,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    return () => {
      dispatch(clearScheduleWorkingPoints());
    };
  }, [dispatch]);

  const handleOnSubmit = useCallback(
    async ({ handleClose }: any) => {
      if (!clientSelectedWorkingPoint || !selectedWorkingPoint) {
        return;
      }
      setIsLoading(true);

      const resultAction = await dispatch(
        fetchUpdateWorkingPoint({
          page: 1,
          perPage: 50,
          deliveryPoint: clientSelectedWorkingPoint?.pctDeLivrare,
          clientId: clientId,
          localitate: clientSelectedWorkingPoint?.localitate,
          judet: clientSelectedWorkingPoint?.judet,
          adresa: clientSelectedWorkingPoint?.adresa,
          idPctLucru: clientSelectedWorkingPoint?.idPctLucru,
          idPctLucruSelectat: selectedWorkingPoint?.id_punctlucru,
        }),
      );

      if (
        checkEndpointStatus(resultAction, fetchUpdateWorkingPoint) !==
        endpointStatus.pending
      ) {
        setIsLoading(false);
      }

      if (
        checkEndpointStatus(resultAction, fetchUpdateWorkingPoint) ===
        endpointStatus.fulfilled
      ) {
        handleClose && handleClose();
      }
    },
    [clientId, clientSelectedWorkingPoint, dispatch, selectedWorkingPoint],
  );

  const onClose = useCallback(() => {
    dispatch(setClientSelectedWorkingPoint(null));
  }, [dispatch]);

  return (
    <ModalList
      dynamicModalOpen={!!clientSelectedWorkingPoint}
      onClose={onClose}
      label="Punct de lucru"
      searchPlaceholder={"Cauta punct de lucru"}
      modalTitle={"Cauta punct de lucru"}
      items={initialClientsWorkingPointsList}
      modalActions={workingPointModalActions}
      selectedItem={{
        id: selectedWorkingPoint?.id_punctlucru || "",
        label: selectedWorkingPoint?.denumire || "",
      }}
      setSelectedItem={setSelectedWorkingPoint}
      withCloseAfterSelect={false}
      CustomModalFooter={({ handleClose }: any) => (
        <div className="d-flex justify-content-between">
          <CustomButton onClick={handleClose}>Inapoi</CustomButton>
          <CustomButton
            variant="contained"
            loading={isLoading}
            onClick={() => {
              handleOnSubmit({ handleClose });
            }}>
            Salveaza
          </CustomButton>
        </div>
      )}
      CustomOpenModalComponent={() => <></>}
    />
  );
}

export default ClientWorkingPointModal;
