import { Trans } from "react-i18next";
import { Typography, TypographyProps } from "@mui/material";

export interface TextProps extends TypographyProps {
  children?: string | number | JSX.Element | JSX.Element[] | React.ReactNode;
  i18nKey?: string;
  i18nVariable?: object;
  isBold?: boolean;
  forId?: string;
  component?: any;
}

const Text = ({
  children,
  i18nKey,
  i18nVariable,
  isBold,
  forId,
  component,
  ...otherProps
}: TextProps) => {
  const customizeProps = {
    ...otherProps,
    className: `${isBold && "fw-bolder"} ${otherProps.className}`,
  };

  const i18nKeyValue = i18nKey ?? children;

  return (
    <WithLabelWrapper forId={forId} className={customizeProps.className}>
      {/* @ts-ignore */}
      <Typography {...customizeProps} htmlFor={forId} component={component}>
        {i18nKey ? (
          <Trans components={{ bold: <strong /> }} values={i18nVariable}>
            {i18nKeyValue}
          </Trans>
        ) : (
          i18nKeyValue
        )}
      </Typography>
    </WithLabelWrapper>
  );
};

const WithLabelWrapper = ({
  children,
  forId,
  className,
}: {
  children: React.ReactNode | JSX.Element | JSX.Element[];
  forId?: string;
  className?: string;
}) => {
  if (forId) {
    return (
      <label htmlFor={forId} className={className}>
        {children}
      </label>
    );
  }
  return <>{children}</>;
};

export default Text;
