import React from "react";

import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

type Props = {
  title?: string;
  className?: string;
  color?: string;
  subtitle?: string;
  subtitleValue?: string | number;
  children?: React.ReactNode;
  navigationLinks?: { link: string; label: string }[];
};

const MenuHeaderCategory = ({
  title,
  className = "",
  color = "primary",
  subtitle,
  subtitleValue,
  navigationLinks,
}: Props) => {
  return (
    <div
      className={`px-9 pt-7 card-rounded min-h-175px w-100 bg-${color} ${className}`}>
      <div className="d-flex flex-stack">
        <div className="d-flex flex-row w-100 justify-content-between align-items-center">
          <h3 className="m-0 text-white fw-bolder fs-3">{title}</h3>

          {!!navigationLinks && (
            <div>
              {navigationLinks?.map(({ link, label }, index: number) => {
                return (
                  <Link key={index} to={link}>
                    <button
                      type="button"
                      className="btn btn-sm btn-secondary fw-bolder">
                      <Typography className="text-black">{label}</Typography>
                    </button>
                  </Link>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {subtitle && subtitleValue && (
        <div className="d-flex text-center flex-column text-white pt-8">
          <span className="fw-bold fs-5">{subtitle}</span>
          <span className="fw-bolder fs-2x pt-1">{subtitleValue}</span>
        </div>
      )}
      <div className="h-100px" />
    </div>
  );
};

export default MenuHeaderCategory;
