import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import ContentCard from "../../../components/misc/ContentCard";
import { Formik } from "formik";
import Dropdown, { DropdownValue } from "../../../components/misc/Dropdown";
import FormButtons from "../../../components/misc/FormButtons";

import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  fetchAssignDriverToRoute,
  fetchIntranodesNomenclatures,
} from "../../../redux/api/assign.api";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../helpers/apiHelper";
import DatePicker from "../../../components/misc/DatePicker";
import { dateForDatabase } from "../../../utils/dateAndTime";
import { getWorkingRoutesNodes } from "../../../redux/api/expedition.api";

interface initialValuesInterface {
  sofer_data_start: string;
  sofer_data_stop: string;
  selectedIntranod: DropdownValue | null;
  selectedStartingNode: DropdownValue | null;
  selectedEndingNode: DropdownValue | null;
  selectedCar: DropdownValue | null;
}

const initialValues: initialValuesInterface = {
  sofer_data_start: "",
  sofer_data_stop: "",
  selectedIntranod: null,
  selectedStartingNode: null,
  selectedEndingNode: null,
  selectedCar: null,
};

const formikSchema = Yup.object({
  sofer_data_start: Yup.date()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .required("Va rugam introduceti data"),
  selectedIntranod: Yup.object()
    .required("Va rugam selectati ruta intranod")
    .nullable(),
  selectedStartingNode: Yup.object()
    .required("Va rugam selectati nodul de start")
    .nullable(),
  selectedEndingNode: Yup.object()
    .required("Va rugam selectati nodul de stop")
    .nullable(),
  selectedCar: Yup.object().required("Va rugam selectati masina").nullable(),
});

function AssignDriverIntranod({ withUpdate = false }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [formikValues, setFormikValues] =
    useState<initialValuesInterface>(initialValues);
  const [isSaving, setIsSaving] = useState(false);

  const {
    assign: {
      intranodesNomenclatures: {
        data: { data: intranodesNomenclatures },
        isLoading: isLoadingIntranodesNomenclatures,
      },
      assignedDriverData,
    },
    expedition: {
      workingRoutesNodes: {
        data: workingRoutesNodes,
        isLoading: isLoadingNodes,
      },
    },
    fleet: {
      fleetData: {
        data: { data: fleetData },
        isLoading: isLoadingFleet,
      },
    },
  } = useAppSelector((state) => ({
    assign: state.assign,
    expedition: state.expedition,
    fleet: state.fleet,
  }));

  const {
    sofer_data_start,
    sofer_data_stop,
    id_ruta_intranod,
    id_user,
    nrauto,
  } = assignedDriverData || {};

  useEffect(() => {
    dispatch(
      fetchIntranodesNomenclatures({
        perPage: 10000,
      }),
    );
    dispatch(
      getWorkingRoutesNodes({
        page: 1,
        perPage: 10000,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (withUpdate && intranodesNomenclatures.length && fleetData.length) {
      const selectedRouteIntranod = intranodesNomenclatures.find(
        (item) => item.id_ruta_intranod === id_ruta_intranod,
      );
      const selectedCar = fleetData?.find((item) => item.nrauto === nrauto);

      setFormikValues((prev) => ({
        ...prev,
        sofer_data_start: sofer_data_start || "",
        sofer_data_stop: sofer_data_stop || "",
        selectedIntranod: selectedRouteIntranod
          ? {
              label: selectedRouteIntranod.denumire,
              id: selectedRouteIntranod.id_ruta_intranod,
            }
          : null,
        selectedCar: selectedCar
          ? {
              label: selectedCar.nrauto,
              id: selectedCar.id_auto,
            }
          : null,
      }));
    }
  }, [
    withUpdate,
    nrauto,
    sofer_data_start,
    sofer_data_stop,
    id_ruta_intranod,
    fleetData,
    intranodesNomenclatures,
  ]);

  const handleSave = async (value: initialValuesInterface) => {
    setIsSaving(true);

    const resultAction = await dispatch(
      fetchAssignDriverToRoute({
        ...value,
        id_user: id_user,
        sofer_data_start: dateForDatabase(value.sofer_data_start),
        sofer_data_stop: dateForDatabase(value.sofer_data_stop),
        id_ruta_intranod: value.selectedIntranod?.id,
        id_auto: value.selectedCar?.id,
        id_nod_start: value.selectedStartingNode?.id,
        id_nod_stop: value.selectedEndingNode?.id,
      }),
    );

    if (
      checkEndpointStatus(resultAction, fetchAssignDriverToRoute) !==
      endpointStatus.pending
    ) {
      setIsSaving(false);
    }

    if (
      checkEndpointStatus(resultAction, fetchAssignDriverToRoute) ===
      endpointStatus.fulfilled
    ) {
      navigate(-1);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const intranodesNomenclaturesDropdownValues = useMemo(
    () =>
      intranodesNomenclatures?.map((item) => ({
        label: item.denumire,
        id: item.id_ruta_intranod,
      })),
    [intranodesNomenclatures],
  );

  const fleetNomenclaturesDropdownValues = useMemo(
    () =>
      fleetData?.map((item) => ({
        label: item.nrauto,
        id: item.id_auto,
      })),
    [fleetData],
  );

  const workingNodesDropdownValues = useMemo(
    () =>
      workingRoutesNodes.data?.map((item) => ({
        label: item.nodNume,
        id: item.id_nod,
      })),
    [workingRoutesNodes.data],
  );

  return (
    <ContentCard
      isLoading={
        isLoadingIntranodesNomenclatures || isLoadingFleet || isLoadingNodes
      }
      withShadow={false}
      withPadding={false}>
      <Formik
        enableReinitialize
        initialValues={formikValues}
        validationSchema={formikSchema}
        onSubmit={handleSave}>
        {({
          values,
          isSubmitting,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
        }) => {
          const { selectedIntranod, selectedCar } = values;

          return (
            <form
              className="form w-100"
              onSubmit={handleSubmit}
              noValidate
              id="kt_login_signin_form">
              <div className="d-md-flex d-block">
                <DatePicker
                  label="Data incepere valabilitate"
                  minDate={new Date()}
                  value={values.sofer_data_start}
                  handleChangeDate={(newDate) => {
                    setFieldValue("sofer_data_start", newDate);
                  }}
                  error={errors["sofer_data_start"]}
                  touched={touched["sofer_data_start"]}
                />

                <DatePicker
                  label="Data sfarsit valabilitate"
                  minDate={new Date()}
                  value={values.sofer_data_stop}
                  handleChangeDate={(newDate) => {
                    setFieldValue("sofer_data_stop", newDate);
                  }}
                  error={errors["sofer_data_stop"]}
                  touched={touched["sofer_data_stop"]}
                  withVerticalSpacer
                />
              </div>
              <Dropdown
                className="my-4"
                title={"Selecteaza ruta intranod"}
                data={intranodesNomenclaturesDropdownValues}
                singleValue={selectedIntranod}
                setSingleValue={(value) => {
                  setFieldValue("selectedIntranod", value);
                }}
                error={errors["selectedIntranod"]}
                touched={touched["selectedIntranod"]}
              />
              <div className="d-flex">
                <Dropdown
                  className="my-4 me-2"
                  title={"Selecteaza nodul de start"}
                  data={workingNodesDropdownValues}
                  singleValue={values.selectedStartingNode}
                  setSingleValue={(value) => {
                    setFieldValue("selectedStartingNode", value);
                  }}
                  error={errors["selectedStartingNode"]}
                  touched={touched["selectedStartingNode"]}
                />
                <Dropdown
                  className="my-4"
                  title={"Selecteaza nodul de stop"}
                  data={workingNodesDropdownValues}
                  singleValue={values.selectedEndingNode}
                  setSingleValue={(value) => {
                    setFieldValue("selectedEndingNode", value);
                  }}
                  error={errors["selectedEndingNode"]}
                  touched={touched["selectedEndingNode"]}
                />
              </div>

              <Dropdown
                className="my-4"
                title={"Selecteaza masina"}
                data={fleetNomenclaturesDropdownValues}
                singleValue={selectedCar}
                setSingleValue={(value) => {
                  setFieldValue("selectedCar", value);
                }}
                error={errors["selectedCar"]}
                touched={touched["selectedCar"]}
              />

              <FormButtons
                goBackLabel="Anuleaza"
                goNextLabel="Salveaza"
                handleGoBack={handleCancel}
                nextButtonDisabled={isSubmitting}
                nextButtonLoading={isSaving}
              />
            </form>
          );
        }}
      </Formik>
    </ContentCard>
  );
}

export default AssignDriverIntranod;
