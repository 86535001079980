import { useNavigate } from "react-router-dom";
import CustomButton from "../../../../../components/CustomButton";
import SvgIcon from "../../../../../helpers/SvgIcon";
import { useAppDispatch } from "../../../../../redux/hooks";
import { setFleetFeatureData } from "../../../../../redux/reducer/fleet.reducer";
import { FleetFeaturesDataInterface } from "../../../../../redux/interfaces/fleet.interface";
import { fetchDeleteFeature } from "../../../../../redux/api/fleet.api";
import { crudRoutes, fleetRoutes } from "../../../../../router/routesConstants";
import usePermissions from "../../../../../router/usePermissions";
import userRoles from "../../../../../constants/userRoles";

function FleetFeaturesActionButton({
  crudData,
  itemId,
}: {
  itemId: string;
  crudData: FleetFeaturesDataInterface;
  tableContext: any;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAuthorized } = usePermissions();

  const {
    CAR_FEATURE_DELETE_PERMISSION,
    CAR_FEATURE_EDIT_PERMISSION,
    CAR_FEATURE_DOCUMENTS_DISPLAY_PERMISSION,
  } = {
    CAR_FEATURE_EDIT_PERMISSION: isAuthorized(userRoles.CAR_FEATURE_EDIT),
    CAR_FEATURE_DELETE_PERMISSION: isAuthorized(userRoles.CAR_FEATURE_DELETE),
    CAR_FEATURE_DOCUMENTS_DISPLAY_PERMISSION: isAuthorized(
      userRoles.CAR_FEATURE_DOCUMENTS_DISPLAY,
    ),
  };

  const handleEdit = () => {
    dispatch(setFleetFeatureData(crudData));
    navigate(`${crudRoutes.edit}?nrauto=${crudData.nrauto}`);
  };

  const handleGoToDocuments = () => {
    navigate(`${itemId}/${fleetRoutes.documents}?nrauto=${crudData.nrauto}`);
  };

  const handleDelete = () => {
    dispatch(fetchDeleteFeature({ id: itemId }));
  };

  return (
    <div>
      {CAR_FEATURE_DOCUMENTS_DISPLAY_PERMISSION && (
        <CustomButton
          variant="contained"
          className="mt-1 me-1"
          tooltipTitle="Introducere documente echipament"
          onClick={handleGoToDocuments}>
          <SvgIcon type="DOCUMENTS" />
        </CustomButton>
      )}
      {CAR_FEATURE_EDIT_PERMISSION && (
        <CustomButton
          variant="contained"
          className="mt-1 me-1"
          tooltipTitle="Editeaza caracteristici echipament"
          onClick={handleEdit}>
          <SvgIcon type="EDIT" />
        </CustomButton>
      )}
      {CAR_FEATURE_DELETE_PERMISSION && (
        <CustomButton
          variant="contained"
          color="error"
          className="mt-1 me-1"
          tooltipTitle="Sterge caracteristicile echipamentului"
          withConfirmationModal={{
            modalTitle: "Doriti sa stergeti acest echipament?",
            modalLeftButtonColor: "inherit",
            modalRightButtonOnClick: handleDelete,
            modalRightButtonText: "Sterge",
            modalLeftButtonText: "Anuleaza",
          }}>
          <SvgIcon type="DELETE" />
        </CustomButton>
      )}
    </div>
  );
}

export default FleetFeaturesActionButton;
