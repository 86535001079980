import MenuCategory from "../../components/MenuCategory/MenuCategory";
import MenuHeaderCategory from "../../components/MenuCategory/MenuHeaderCategory";
import useAppMenuRoutes from "../../router/useAppMenuRoutes";

function Home() {
  const categories = useAppMenuRoutes();

  return (
    <div className="d-flex flex-wrap">
      {categories.map(
        (
          {
            color,
            subtitle,
            subtitleValue,
            title,
            categories,
            navigationLinks,
          },
          index,
        ) => {
          return (
            <MenuCategory
              key={index}
              categories={categories}
              style={{
                display: "flex",
                flexBasis: "30%",
                minWidth: "300px",
                flexGrow: 1,
              }}
              MenuHeaderCategory={
                <MenuHeaderCategory
                  title={title}
                  color={color}
                  subtitle={subtitle}
                  subtitleValue={subtitleValue}
                  navigationLinks={navigationLinks}
                />
              }></MenuCategory>
          );
        },
      )}
    </div>
  );
}

export default Home;
