import React from "react";
import Text from "../../../../styleguide/Text";
import CustomButton from "../../../../components/CustomButton";
import SvgIcon from "../../../../helpers/SvgIcon";
import usePermissions from "../../../../router/usePermissions";
import userRoles from "../../../../constants/userRoles";

function TableCustomHeader() {
  const { isAuthorized } = usePermissions();
  const CAR_CREATE_PERMISSION = isAuthorized(userRoles.CAR_CREATE);

  return (
    <div className="d-flex align-items-center justify-content-between">
      <Text
        variant="h3"
        component="h3"
        className="card-title mb-4"
        gutterBottom>
        Flota
      </Text>
      {CAR_CREATE_PERMISSION && (
        <CustomButton
          variant="contained"
          className="mt-1 me-1"
          tooltipTitle="Adauga masina noua"
          navigate="create">
          <SvgIcon type="ADD" />
        </CustomButton>
      )}
    </div>
  );
}

export default TableCustomHeader;
