import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";

import ContentCard from "../../../components/misc/ContentCard";
import CustomButton from "../../../components/CustomButton";
import TextInput from "../../../components/misc/TextInput";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import { postUserDetails } from "../../../redux/api/admin.api";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../helpers/apiHelper";
import Dropdown, { DropdownValue } from "../../../components/misc/Dropdown";
import { getWorkingRoutesNodes } from "../../../redux/api/expedition.api";

interface initialValuesInterface {
  userName: string;
  firstName: string;
  lastName: string;
  password: string;
  verifyPassword: string;
  userFunction: number | null;
  node: number | null;
}

const initialValues: initialValuesInterface = {
  userName: "",
  firstName: "",
  lastName: "",
  password: "",
  verifyPassword: "",
  userFunction: null,
  node: 1,
};

const productSchema = Yup.object({
  userName: Yup.string().required("Campul este obligatoriu"),
  firstName: Yup.string().required("Campul este obligatoriu"),
  lastName: Yup.string().required("Campul este obligatoriu"),
  password: Yup.string().required("Campul este obligatoriu"),
  verifyPassword: Yup.string()
    .required("Campul este obligatoriu")
    .oneOf([Yup.ref("password"), null], "Parolele nu se potrivesc"),
});

const userFunctionsDropdownValue = [
  { id: 10, label: "Livrator" },
  { id: 11, label: "Sofer internod" },
  { id: 12, label: "Manager nod (ierarhic direct pentru livratori si soferi)" },
  { id: 13, label: "Dispecer logistica" },
  { id: 14, label: "Manager logistica (ierarhic direct pt manager nod)" },
  { id: 15, label: "Persoana responsabila farmacie" },
  { id: 16, label: "Manager general" },
  { id: 77, label: "Superuser" },
];

function CreateUser() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    expedition: {
      workingRoutesNodes: {
        data: { data: userAllNodes },
      },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const [isLoading, setIsLoading] = useState(false);

  const [selectedUserFunction, setSelectedUserFunction] =
    useState<DropdownValue | null>(null);

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(
      getWorkingRoutesNodes({
        page: 1,
        perPage: 1000,
      }),
    );
  }, [dispatch]);

  const handleSaveProductDetails = async (value: initialValuesInterface) => {
    setIsLoading(true);

    const resultAction = await dispatch(
      postUserDetails({
        userId: null,
        userDetails: value,
      }),
    );

    if (
      checkEndpointStatus(resultAction, postUserDetails) !==
      endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, postUserDetails) ===
      endpointStatus.fulfilled
    ) {
      navigate(-1);
    }
  };

  const userAllNodesNew = useMemo(
    () =>
      userAllNodes.map((item) => {
        return {
          id: item.id_nod,
          label: item.nodNume,
        };
      }),
    [userAllNodes],
  );

  return (
    <ContentCard cardTitle="Creeaza utilizator">
      <Formik
        initialValues={initialValues}
        validationSchema={productSchema}
        onSubmit={handleSaveProductDetails}>
        {({
          isValid,
          errors,
          touched,
          values,
          getFieldProps,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => {
          const selectedType = userAllNodesNew?.find(
            (item) => item.id === values.node,
          );

          return (
            <form
              className="form w-100"
              onSubmit={handleSubmit}
              noValidate
              id="kt_login_signin_form">
              <TextInput
                label="Username"
                name="userName"
                error={errors["userName"]}
                touched={touched["userName"]}
                inputProps={getFieldProps("userName")}
              />
              <TextInput
                label="Nume"
                name="lastName"
                error={errors["lastName"]}
                touched={touched["lastName"]}
                inputProps={getFieldProps("lastName")}
              />
              <TextInput
                label="Prenume"
                name="firstName"
                error={errors["firstName"]}
                touched={touched["firstName"]}
                inputProps={getFieldProps("firstName")}
              />
              <TextInput
                label="Parola"
                name="password"
                type="password"
                error={errors["password"]}
                touched={touched["password"]}
                inputProps={getFieldProps("password")}
              />
              <TextInput
                label="Verifica Parola"
                name="verifyPassword"
                type="password"
                error={errors["verifyPassword"]}
                touched={touched["verifyPassword"]}
                inputProps={getFieldProps("verifyPassword")}
              />
              <Dropdown
                className="mb-4 me-2"
                title="Selecteaza functia"
                data={userFunctionsDropdownValue}
                singleValue={selectedUserFunction}
                setSingleValue={(value) => {
                  setSelectedUserFunction(value);
                  setFieldValue("userFunction", value?.id);
                }}
              />

              <Dropdown
                className="my-4"
                title="Selecteaza nod"
                data={userAllNodesNew}
                singleValue={selectedType}
                setSingleValue={(value) => {
                  setFieldValue("node", value?.id);
                }}
              />

              <div className="d-flex justify-content-between">
                <CustomButton
                  type="submit"
                  variant="outlined"
                  onClick={handleGoBack}>
                  Anuleaza
                </CustomButton>
                <CustomButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting || isLoading}
                  disabled={!isValid || isSubmitting}>
                  Salveaza
                </CustomButton>
              </div>
            </form>
          );
        }}
      </Formik>
    </ContentCard>
  );
}

export default CreateUser;
