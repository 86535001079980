import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "./assetHelper";

type Props = {
  className?: string;
  path: string;
  svgClassName?: string;
  fill?: string;
  stroke?: string;
  color?: string;
};

const KTSVG: React.FC<Props> = ({
  className = "",
  path,
  svgClassName = "mh-50px",
  fill,
  stroke,
  color,
}) => {
  return (
    <span className={`svg-icon ${className}`}>
      <SVG
        src={toAbsoluteUrl(path)}
        className={svgClassName}
        fill={fill}
        color={color}
        stroke={stroke}
      />
    </span>
  );
};

export { KTSVG };
