import React from "react";

type GlobalContextType = {
  theme: boolean | (() => boolean);
  setTheme: React.Dispatch<React.SetStateAction<boolean>>;
};

export const GlobalContext = React.createContext<GlobalContextType | undefined>(
  undefined,
);
