import React from "react";
import Text from "../../../../styleguide/Text";
import CustomButton from "../../../../components/CustomButton";
import SvgIcon from "../../../../helpers/SvgIcon";
import useRoutesHelper from "../../../../hooks/useRoutesHelper";
import userRoles from "../../../../constants/userRoles";
import usePermissions from "../../../../router/usePermissions";

function TableCustomHeader() {
  const { routeParams } = useRoutesHelper();
  const { nrauto } = routeParams as { nrauto: string };

  const { isAuthorized } = usePermissions();

  const CAR_DOCUMENT_CREATE_PERMISSION = isAuthorized(
    userRoles.CAR_DOCUMENT_CREATE,
  );

  return (
    <div className="d-flex align-items-center justify-content-between">
      <Text
        variant="h3"
        component="h3"
        className="card-title mb-4"
        gutterBottom>
        Documente masina {nrauto}
      </Text>
      {CAR_DOCUMENT_CREATE_PERMISSION && (
        <CustomButton
          variant="contained"
          className="mt-1 me-1"
          tooltipTitle="Creare"
          navigate={`create?nrauto=${nrauto}`}>
          <SvgIcon type="ADD" />
        </CustomButton>
      )}
    </div>
  );
}

export default TableCustomHeader;
