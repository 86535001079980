import { useNavigate } from "react-router-dom";
import CustomButton from "../../../../../components/CustomButton";
import SvgIcon from "../../../../../helpers/SvgIcon";
import { FleetDataInterface } from "../../../../../redux/interfaces/fleet.interface";
import { setFleetCarData } from "../../../../../redux/reducer/fleet.reducer";
import { crudRoutes, fleetRoutes } from "../../../../../router/routesConstants";
import { useAppDispatch } from "../../../../../redux/hooks";
import { fetchDeleteCar } from "../../../../../redux/api/fleet.api";
import usePermissions from "../../../../../router/usePermissions";
import userRoles from "../../../../../constants/userRoles";

function FleetActionButton({
  itemId,
  crudData,
  tableContext,
}: {
  itemId: string;
  crudData: FleetDataInterface;
  tableContext: any;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAuthorized } = usePermissions();

  const {
    CAR_DELETE_PERMISSION,
    CAR_DOCUMENTS_ALL_PERMISSION,
    CAR_DOCUMENTS_PERMISSION,
    CAR_EDIT_PERMISSION,
    CAR_FEATURES_PERMISSION,
  } = {
    CAR_EDIT_PERMISSION: isAuthorized(userRoles.CAR_EDIT),
    CAR_DELETE_PERMISSION: isAuthorized(userRoles.CAR_DELETE),
    CAR_FEATURES_PERMISSION: isAuthorized(userRoles.CAR_FEATURES_DISPLAY),
    CAR_DOCUMENTS_PERMISSION: isAuthorized(userRoles.CAR_DOCUMENTS_DISPLAY),
    CAR_DOCUMENTS_ALL_PERMISSION: isAuthorized(
      userRoles.CAR_DOCUMENTS_ALL_DISPLAY,
    ),
  };

  const handleEdit = () => {
    dispatch(setFleetCarData(crudData));
    navigate(`${itemId}/${crudRoutes.edit}?nrauto=${crudData.nrauto}`);
  };

  const handleGoToFeatures = () => {
    dispatch(setFleetCarData(crudData));
    navigate(`${itemId}/${fleetRoutes.features}?nrauto=${crudData.nrauto}`);
  };

  const handleGoToDocuments = () => {
    dispatch(setFleetCarData(crudData));
    navigate(`${itemId}/${fleetRoutes.documents}?nrauto=${crudData.nrauto}`);
  };

  const handleGoToAllDocuments = () => {
    dispatch(setFleetCarData(crudData));
    navigate(`${itemId}/${fleetRoutes.allDocumets}?nrauto=${crudData.nrauto}`);
  };

  const handleDelete = () => {
    dispatch(
      fetchDeleteCar({
        id: itemId,
        page: tableContext.page,
        perPage: tableContext.perPage,
      }),
    );
  };

  return (
    <div>
      {CAR_EDIT_PERMISSION && (
        <CustomButton
          variant="contained"
          className="mt-1 me-1"
          tooltipTitle="Editare date autovehicul"
          onClick={handleEdit}>
          <SvgIcon type="EDIT" />
        </CustomButton>
      )}
      {CAR_FEATURES_PERMISSION && (
        <CustomButton
          variant="contained"
          className="mt-1 me-1"
          tooltipTitle="Echipamente calitate transport"
          onClick={handleGoToFeatures}>
          <SvgIcon type="SENSORS" />
        </CustomButton>
      )}
      {CAR_DOCUMENTS_PERMISSION && (
        <CustomButton
          variant="contained"
          className="mt-1 me-1"
          tooltipTitle="Documente"
          onClick={handleGoToDocuments}>
          <SvgIcon type="DOCUMENTS" />
        </CustomButton>
      )}
      {CAR_DOCUMENTS_ALL_PERMISSION && (
        <CustomButton
          variant="contained"
          className="mt-1 me-1"
          tooltipTitle="Toate documentele"
          onClick={handleGoToAllDocuments}>
          <SvgIcon
            type="MULTIPLE_DOCUMENTS"
            className="svg-icon-2"
            fill="white"
          />
        </CustomButton>
      )}
      {CAR_DELETE_PERMISSION && (
        <CustomButton
          variant="contained"
          color="error"
          className="mt-1 me-1"
          withConfirmationModal={{
            modalTitle:
              "Doriti sa stergeti datele de identificare pentru acest autovehicul?",
            modalLeftButtonColor: "inherit",
            modalRightButtonOnClick: handleDelete,
            modalRightButtonText: "Sterge",
            modalLeftButtonText: "Anuleaza",
          }}>
          <SvgIcon type="DELETE" />
        </CustomButton>
      )}
    </div>
  );
}

export default FleetActionButton;
