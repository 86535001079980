import { forwardRef } from "react";
import { Snackbar, SnackbarOrigin } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import Text from "../styleguide/Text";
import { closeGlobalToast } from "../redux/reducer/globalComponents.reducer";

export interface ToastGlobalState extends SnackbarOrigin {
  open: boolean;
  message: string;
  severity?: "success" | "error" | "info" | "warning";
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function GlobalToast() {
  const dispatch = useAppDispatch();

  const { toast } = useAppSelector((state) => ({
    toast: state.globalComponents.toastGlobal,
  }));

  const { vertical, horizontal, open, message, severity, autoHideDuration } =
    toast;

  const handleClose = () => {
    dispatch(closeGlobalToast());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
      key={vertical + horizontal}
      autoHideDuration={autoHideDuration}>
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{ width: "100%" }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Text>{message}</Text>
      </Alert>
    </Snackbar>
  );
}

export default GlobalToast;
