import { createSlice } from "@reduxjs/toolkit";

import { fetchUserToken } from "../api/auth.api";

export interface StateAuthInterface {
  token: null | string;
  isLoadingToken: boolean;
  isErrorToken: boolean;
  errorMessageToken?: string;
}

const tokenValue = localStorage.getItem("token");

export const defaultStateAuth: StateAuthInterface = {
  token: tokenValue ?? null,
  isLoadingToken: false,
  isErrorToken: false,
  errorMessageToken: "",
};

const auth = createSlice({
  name: "auth",
  initialState: defaultStateAuth,
  reducers: {
    removeUserToken(state) {
      localStorage.removeItem("token");
      return {
        ...state,
        isLoadingToken: false,
        token: null,
      };
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed\
    builder.addCase(fetchUserToken.pending, () => {
      return {
        ...defaultStateAuth,
        isLoadingToken: true,
      };
    });
    builder.addCase(fetchUserToken.rejected, (state, action: any) => {
      return {
        ...state,
        isLoadingToken: false,
        errorMessageToken: action.payload?.message,
        isErrorToken: true,
      };
    });
    builder.addCase(fetchUserToken.fulfilled, (state, action) => {
      return {
        ...state,
        isLoadingToken: false,
        token: action.payload.token,
      };
    });
  },
});

export const { removeUserToken } = auth.actions;

export default auth.reducer;
