import { useCallback, useEffect, useState } from "react";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";

function useRoutesHelper() {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchRouteParams] = useSearchParams();

  const [routeParams, setRouteParams] = useState<any>({});

  const routeMainParam = useParams();

  const currentUrlWithHostname = window.location.href
    .replace("http://", "")
    .replace("https://", "");

  const endOfHostName = currentUrlWithHostname.split("/");
  const currentUrl = endOfHostName.slice(1).join("/");

  useEffect(() => {
    // @ts-ignore
    const routeParams = Object.fromEntries([...searchRouteParams]);
    if (
      routeParams && // 👈 null and undefined check
      Object.keys(routeParams).length !== 0 &&
      Object.getPrototypeOf(routeParams) === Object.prototype
    ) {
      setRouteParams(routeParams);
    }
  }, [searchRouteParams]);

  const handleSetQuerryParams = useCallback(
    (newParams: object) => {
      const searchParams = new URLSearchParams(location.search);

      // Add new parameters from newParams object
      Object.entries(newParams).forEach(([key, value]) => {
        searchParams.set(key, value);
      });

      // Construct the new URL with updated search parameters
      const newUrl = `${location.pathname}?${searchParams.toString()}`;

      // Navigate to the new URL
      navigate(newUrl, { replace: true });
    },
    [location.pathname, location.search, navigate],
  );

  return {
    routeMainParam,
    routeParams,
    searchRouteParams,
    currentUrl,
    handleSetQuerryParams,
  };
}

export default useRoutesHelper;
