import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest, errorFormatHandler } from "../../helpers/apiHelper";

export const fetchUsers = createAsyncThunk(
  "/admin/users",
  async (
    transferData: {
      page: number;
      perPage: number;
      username: string;
      firstName: string;
      secondName: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { page, perPage, username, firstName, secondName } = transferData;
      const { data } = await apiRequest(
        `/showAllUsers?page=${page ?? 1}&perPage=${
          perPage ?? 5
        }&username=${username}&firstName=${firstName}&secondName=${secondName}`,
        "GET",
      );

      return data;
    } catch (err: any) {
      console.warn("/admin/users", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchUserDetails = createAsyncThunk(
  "/admin/user",
  async (userId: string, { rejectWithValue }) => {
    try {
      const { data } = await apiRequest(`/returnUser/${userId}`, "GET");

      return data;
    } catch (err: any) {
      console.warn("/admin/user", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const postUserDetails = createAsyncThunk(
  "/admin/user",
  async (
    {
      userId,
      userDetails,
      isUpdating,
    }: {
      userId: string | null;
      userDetails: {
        userName: string;
        firstName: string;
        lastName: string;
        password: string;
        userFunction: number | null;
        node: number | null;
      };
      isUpdating?: boolean;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(`/register`, "POST", {
        userId,
        ...userDetails,
        isUpdating,
      });

      return data;
    } catch (err: any) {
      console.warn("/admin/user", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postDeleteUser = createAsyncThunk(
  "/admin/deleteUser",
  async (params: { userId: string }, { rejectWithValue, dispatch }) => {
    const { userId } = params;
    try {
      const { data } = await apiRequest(`/deleteUser/${userId}`, "GET");
      dispatch(
        fetchUsers({
          page: 1,
          perPage: 25,
          username: "",
          firstName: "",
          secondName: "",
        }),
      );
      return data;
    } catch (err: any) {
      console.warn("/admin/deleteUser", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchUserPermissionsWeb = createAsyncThunk(
  "/admin/fetchUserPermissionsWeb",
  async (userId: string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(`/ruteWebApp/${userId}`, "GET");

      return data;
    } catch (err: any) {
      console.warn("/admin/fetchUserPermissionsWeb", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postUserPermissionsWeb = createAsyncThunk(
  "/admin/postUserPermissionsWeb",
  async (
    transferData: {
      userId: string;
      permissions: {
        idRoute: number;
        subRoutes: {
          idRoute: number;
          isChecked: boolean;
        }[];
      }[];
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/addUserRoutes", "POST", transferData);

      return data;
    } catch (err: any) {
      console.warn("/admin/postUserPermissionsWeb", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchUserPermissionsMobile = createAsyncThunk(
  "/admin/fetchUserPermissionsMobile",
  async (userId: string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(`/ruteMobileApp/${userId}`, "GET");

      return data;
    } catch (err: any) {
      console.warn("/admin/fetchUserPermissionsMobile", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postUserPermissionsMobile = createAsyncThunk(
  "/admin/postUserPermissionsMobile",
  async (
    transferData: {
      userId: string;
      permissions: {
        idRoute: number;
      }[];
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "addUserRoutesMobile",
        "POST",
        transferData,
      );

      return data;
    } catch (err: any) {
      console.warn("/admin/postUserPermissionsMobile", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);
