import React from "react";
// @ts-ignore
import Lottie from "lottie-react";

import loadingAnimation from "../assets/animations/loading.json";

type lottieAnimationType = "loading";

function LottieAnimation({
  type,
  height = "100%",
  width = "100%",
}: {
  type: lottieAnimationType;
  height?: string | number;
  width?: string | number;
}) {
  if (!type) {
    return null;
  }

  const getType = () => {
    switch (type) {
      case "loading":
        return loadingAnimation;
      default:
        return loadingAnimation;
    }
  };

  return <Lottie animationData={getType()} style={{ height, width }} />;
}

export default LottieAnimation;
