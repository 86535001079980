import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchFleetFeatures } from "../../../redux/api/fleet.api";
import useRoutesHelper from "../../../hooks/useRoutesHelper";
import TableWidget from "../../../components/tables/TableWidget";
import {
  fleetFeaturesTableHeader,
  getFleetFeaturesTableData,
} from "./components/tableConfigs/fleetFeaturesTableConfig";
import FleetFeaturesActionButton from "./components/TableActionsButtonsComponents/FleetFeaturesActionButton";
import { useCallback } from "react";
import { clearFleetFeatures } from "../../../redux/reducer/fleet.reducer";
import TableCustomHeader from "./components/TableCustomHeader";

function FleetFeatures() {
  const { routeMainParam } = useRoutesHelper();

  const { carId } = routeMainParam as { carId: string };

  const dispatch = useAppDispatch();

  const {
    fleet: {
      fleetFeatures: { data: fleetFeaturesData, isLoading: isLoadingFleet },
    },
  } = useAppSelector((state) => ({
    fleet: state.fleet,
  }));

  const handleTableActions = () => {
    if (carId) {
      dispatch(fetchFleetFeatures({ id: carId }));
    }
  };

  const handleUnmount = useCallback(() => {
    dispatch(clearFleetFeatures());
  }, [dispatch]);

  return (
    <TableWidget
      CustomHeaderComponent={TableCustomHeader}
      tableHeaderData={fleetFeaturesTableHeader}
      tableItemsData={getFleetFeaturesTableData(fleetFeaturesData)}
      handleTableActions={handleTableActions}
      handleUnmount={handleUnmount}
      borderedRow
      tableLoading={isLoadingFleet}
      ActionButtonsComponent={FleetFeaturesActionButton}
      showSearchForCategories={false}
      withTablePagination={false}
    />
  );
}

export default FleetFeatures;
