import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest, errorFormatHandler } from "../../helpers/apiHelper";

import { fetchUser } from "./user.api";

export const fetchUserToken = createAsyncThunk(
  "/login",
  async (
    params: {
      userName: string;
      password: string;
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { data } = await apiRequest("/login", "POST", params);
      localStorage.setItem("userId", data.userId);
      localStorage.setItem("token", data.token);

      dispatch(fetchUser());
      return data;
    } catch (err: any) {
      console.warn("/login", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);
