import * as Colors from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface Palette {
    tomato: string;
    pink: {
      deep: string;
      hot: string;
      medium: string;
      pale: string;
      light: string;
    };
    white: string;
  }
  interface PaletteOptions {
    tomato: string;
    pink: {
      deep: string;
      hot: string;
      medium: string;
      pale: string;
      light: string;
    };
    white: string;
    black: string;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    tomato: true;
    pink: {
      deep: true;
      hot: true;
      medium: true;
      pale: true;
      light: true;
    };
    white: true;
    black: true;
  }
}

export default {
  ...Colors,
  primary: Colors.blue,
  tomato: "#FF6347",
  pink: {
    deep: "#FF1493",
    hot: "#FF69B4",
    medium: "#C71585",
    pale: "#DB7093",
    light: "#FFB6C1",
  },
  white: "white",
  black: "black",
  red: Colors.red[500],
};
