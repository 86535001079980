import React from "react";
import { Link } from "react-router-dom";
import { KTSVG } from "../../helpers/KTSVG";
import SvgIcon from "../../helpers/SvgIcon";

type Props = {
  className?: string;
  categories?:
    | {
        Icon?: JSX.Element;
        title?: string;
        subtitle?: string;
        value?: string | number;
        isIncreasing?: boolean;
        path?: string;
      }[]
    | null;
  style?: any;
  children?: React.ReactNode;
  MenuHeaderCategory?: JSX.Element;
};

const NavigationWrapper = ({ path, children }: any) => {
  if (path) {
    return (
      <Link to={path} className="mb-1 pe-3 flex-grow-1">
        {children}
      </Link>
    );
  }
  return <div className="mb-1 pe-3 flex-grow-1">{children}</div>;
};

const MenuCategory: React.FC<Props> = ({
  className = "",
  style,
  categories,
  children,
  MenuHeaderCategory,
}) => {
  return (
    <div
      className={`card card-xl-stretch shadow-xs mx-3 mb-9 ${className}`}
      style={style}>
      {/* begin::Body */}
      <div className="card-body p-0">
        {MenuHeaderCategory && MenuHeaderCategory}
        {/* begin::Items */}
        {categories && (
          <div
            className="shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white"
            style={{ marginTop: MenuHeaderCategory ? -100 : 30 }}>
            {/* begin::Item */}
            {categories.map(
              ({ subtitle, title, value, isIncreasing, Icon, path }, index) => {
                return (
                  <div className="d-flex align-items-center mb-4" key={index}>
                    {/* begin::Symbol */}
                    <div className="symbol symbol-45px w-40px me-5">
                      <span className="symbol-label bg-lighten">
                        {Icon || (
                          <SvgIcon type="BULLET_LINK" className="svg-icon-1" />
                        )}
                      </span>
                    </div>
                    {/* end::Symbol */}
                    {/* begin::Description */}
                    <div className="d-flex align-items-center flex-wrap w-100">
                      {/* begin::Title */}
                      <NavigationWrapper path={path}>
                        <>
                          <p className="fs-5 text-gray-800 text-hover-primary fw-bolder mb-1">
                            {title}
                          </p>
                          <div className="text-gray-400 fw-bold fs-7 mb-0">
                            {subtitle}
                          </div>
                        </>
                      </NavigationWrapper>
                      {/* end::Title */}
                      {/* begin::Label */}
                      <div className="d-flex align-items-center">
                        <div className="fw-bolder fs-4 text-gray-800 pe-1">
                          {value}
                        </div>
                        {isIncreasing !== undefined ? (
                          isIncreasing ? (
                            <KTSVG
                              path="/media/icons/duotune/arrows/arr066.svg"
                              className="svg-icon-2 svg-icon-success ms-1"
                            />
                          ) : (
                            <KTSVG
                              path="/media/icons/duotune/arrows/arr065.svg"
                              className="svg-icon-2 svg-icon-danger ms-1"
                            />
                          )
                        ) : undefined}
                      </div>
                      {/* end::Label */}
                    </div>
                    {/* end::Description */}
                  </div>
                );
              },
            )}
            {/* end::Item */}
          </div>
        )}
        {/* end::Items */}
        {children}
      </div>
      {/* end::Body */}
    </div>
  );
};

export default MenuCategory;
