import FilterTable from "./FilterTable";
import { TableSearch } from "./TableSearch";
import useTableCtx from "../tableContext/useTableCtx";
import TableDatePicker from "./TableDatePicker";

function TableHeaderCategories({
  showActionButtons = true,
  showSearchForCategories = true,
  handleCategoryPress,
}: {
  showActionButtons?: boolean;
  showSearchForCategories?: boolean;
  handleCategoryPress?: (name: string, event?: any) => void;
}) {
  const tableContext = useTableCtx()!;

  const { tableHeader: tableHeaderCategories } = tableContext;

  const withSearchOrFilter = tableHeaderCategories.some(
    ({ withSearch, withFilter, withDatePicker }) =>
      withSearch || withFilter || withDatePicker,
  );
  return (
    <thead>
      {showSearchForCategories && withSearchOrFilter && (
        <tr className="fw-bolder text-muted bg-light">
          {tableHeaderCategories.map(
            (
              { value, minW, maxW, withSearch, withFilter, withDatePicker },
              idx,
              categories,
            ) => {
              return (
                <th
                  key={value}
                  style={{ minWidth: minW ?? 130, maxWidth: maxW }}
                  className={`${
                    idx === 0
                      ? "ps-3 rounded-start"
                      : idx === categories.length - 1
                      ? "pe-3 rounded-end"
                      : ""
                  }`}>
                  {withSearch && (
                    <TableSearch value={value} withSearch={withSearch} />
                  )}
                  {withFilter && withFilter.length && (
                    <FilterTable
                      value={value}
                      withFilter={withFilter}
                      minW={minW}
                    />
                  )}
                  {withDatePicker && (
                    <TableDatePicker
                      value={value}
                      withDatePicker={withDatePicker}
                    />
                  )}
                </th>
              );
            },
          )}
          {showActionButtons && (
            <th className="min-w-150px text-end rounded-end"></th>
          )}
        </tr>
      )}
      <tr style={{ height: 10 }} />
      <tr className="fw-bolder text-muted bg-light">
        {tableHeaderCategories.map(
          ({ label, value, minW, maxW }, idx, categories) => {
            return (
              <th
                key={value}
                style={{ minWidth: minW ?? 130, maxWidth: maxW }}
                className={`${
                  idx === 0
                    ? "ps-3 rounded-start"
                    : idx === categories.length - 1
                    ? "pe-3 rounded-end"
                    : ""
                }`}
                onClick={
                  handleCategoryPress && (() => handleCategoryPress(value))
                }>
                {label ?? value}
              </th>
            );
          },
        )}
        {showActionButtons && (
          <th className="min-w-150px text-end rounded-end"></th>
        )}
      </tr>
    </thead>
  );
}

export default TableHeaderCategories;
