function TableHeader({
  CustomHeaderComponent,
}: {
  CustomHeaderComponent?: any;
}) {
  if (!CustomHeaderComponent) {
    return null;
  }

  return <CustomHeaderComponent />;
}

export default TableHeader;
