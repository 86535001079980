import React, { useEffect } from "react";
import useTableCtx from "../tableContext/useTableCtx";

function FilterTable({
  value,
  withFilter,
  minW,
}: {
  value: string;
  minW?: number | string;
  withFilter: {
    value: string | number;
    label: string | number;
  }[];
}) {
  const { handleTableFilter, tableFilter } = useTableCtx()!;

  const tableFilterValue = tableFilter && tableFilter[value];

  const selectedValue = tableFilterValue ?? null;

  useEffect(() => {
    if (value && !tableFilterValue) {
      handleTableFilter(value, withFilter && withFilter[0].value);
    }
  }, [value, withFilter, tableFilterValue, handleTableFilter]);

  const onFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleTableFilter(e.target.name, e.target.value);
  };

  return (
    <div>
      {selectedValue !== null && (
        <select
          name={value}
          data-control={`select-${value}`}
          data-hide-search="true"
          style={{ minWidth: minW ?? 130 }}
          className={`form-select form-select-sm form-select-solid bg-white`}
          value={selectedValue}
          onChange={onFilterChange}>
          {withFilter.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      )}
    </div>
  );
}

export default FilterTable;
