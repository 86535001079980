import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedRoute = ({
  isAuthorized,
  redirectPath = "/login",
}: {
  isAuthorized?: boolean;
  redirectPath?: string;
}) => {
  const location = useLocation();

  if (!isAuthorized) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
