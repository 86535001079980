import { useState, useEffect } from "react";
import useTableCtx from "../tableContext/useTableCtx";
import DatePicker from "../../misc/DatePicker";

const TableDatePicker = ({
  value,
  withDatePicker,
}: {
  value: string;
  minW?: number | string;
  withDatePicker:
    | {
        minDate?: Date;
        maxDate?: Date;
        placeholder?: string;
        withClearData?: boolean;
      }
    | boolean;
}) => {
  const { minDate, maxDate, placeholder, withClearData } =
    typeof withDatePicker === "boolean"
      ? {
          minDate: undefined,
          maxDate: undefined,
          placeholder: "",
          withClearData: false,
        }
      : withDatePicker;

  const { handleTableSearch } = useTableCtx()!;

  const [pickedDate, setPickedDate] = useState<Date | null>(null);

  useEffect(() => {
    if (pickedDate !== undefined) {
      const formatDate = pickedDate ? pickedDate.toISOString() : "";
      handleTableSearch(value, formatDate);
    }
  }, [handleTableSearch, pickedDate, value]);

  return (
    <DatePicker
      textInputClassName="bg-white"
      size="small"
      isRequired
      withVerticalSpacer
      fullWith={false}
      gutterBottom={false}
      minDate={minDate}
      maxDate={maxDate}
      value={pickedDate}
      placeholder={placeholder}
      withClearData={withClearData}
      handleChangeDate={(value) => setPickedDate(value)}
    />
  );
};

export default TableDatePicker;
