import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { ClientsDataInterface } from "../../../../redux/interfaces/expedition.interface";

export const clientsTableHeader: TableHeaderCategoryConfig[] = [
  {
    value: "Id client",
    minW: 125,
  },
  {
    label: "Denumire client",
    value: "denumireClient",
    withSearch: true,
    minW: 125,
  },
  {
    label: "Localitate",
    value: "localitate",
    withSearch: true,
    minW: 125,
  },
  {
    label: "Judet",
    value: "judet",
    withSearch: true,
    minW: 125,
  },
  {
    label: "Adresa",
    value: "adresa",
    withSearch: true,
    minW: 125,
  },
  {
    value: "Persoana contact",
    minW: 125,
  },
  {
    value: "Telefon",
    minW: 125,
  },
  {
    value: "Status",
    minW: 125,
  },
];

export const getClientsTableData: any = (data: ClientsDataInterface[]) =>
  data?.map((item) => {
    return {
      id: item.idClient,
      crudData: item,
      data: [
        {
          title: item.idClient,
        },
        {
          title: item.denumireClient,
        },
        {
          title: item.localitate,
        },
        {
          title: item.judet,
        },
        {
          title: item.adresa,
        },
        {
          title: item.persoanaContact,
        },
        {
          title: item.telefonClient,
        },
        {
          badges: [
            {
              badgeText: item.status ? "Validat" : "Nevalidat",
              badgeColor: item.status ? "light-success" : "light-danger",
            },
          ],
        },
      ],
    };
  });
