import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { TableActions } from "../../../components/tables/tableContext/TableContext";
import TableWidget from "../../../components/tables/TableWidget";
import {
  fleetTableHeader,
  getFleetTableData,
} from "./components/tableConfigs/fleetTableConfig";
import { fetchFleet } from "../../../redux/api/fleet.api";

import FleetActionButton from "./components/TableActionsButtonsComponents/FleetActionButton";
import TableCustomHeader from "./components/TableCustomHeader";

function FleetDisplay() {
  const dispatch = useAppDispatch();

  const {
    fleet: {
      fleetData: {
        data: { data: fleetData, total },
        isLoading: isLoadingFleet,
      },
    },
  } = useAppSelector((state) => ({
    fleet: state.fleet,
  }));

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableSearch
    ) {
      dispatch(
        fetchFleet({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          marca: action.tableSearch?.marca ?? "",
          nrauto: action.tableSearch?.nrauto ?? "",
          model: action.tableSearch?.model ?? "",
        }),
      );
    }
  };

  return (
    <TableWidget
      CustomHeaderComponent={TableCustomHeader}
      tableHeaderData={fleetTableHeader}
      tableItemsData={getFleetTableData(fleetData)}
      totalItems={total}
      borderedRow
      tableLoading={isLoadingFleet}
      ActionButtonsComponent={FleetActionButton}
      handleTableActions={handleTableActions}
    />
  );
}

export default FleetDisplay;
