import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import Text from "../../../styleguide/Text";
import ModalWrapper from "../../Modal/ModalWrapper";
import TextInput from "../TextInput";
import CustomListItem, { CustomItemPress } from "../CustomListItem";
import { useDebounce } from "../../../hooks/useDebounce";

export interface ModalListItem {
  id: number | string;
  label: string | number;
  backgroundColor?: "info" | "success" | "warning" | "danger" | "primary";
}

function ModalList({
  items,
  modalActions,
  label,
  selectedItem,
  setSelectedItem,
  withCloseAfterSelect = true,
  searchPlaceholder,
  modalTitle,
  CustomOpenModalComponent,
  CustomModalFooter,
  dynamicModalOpen,
  onClose,
}: {
  items: ModalListItem[];
  modalActions: (value: { search: string }) => void;
  label?: string;
  selectedItem?: ModalListItem;
  setSelectedItem?: React.Dispatch<React.SetStateAction<any>>;
  withCloseAfterSelect?: boolean;
  searchPlaceholder?: string;
  modalTitle?: string;
  CustomOpenModalComponent?: any;
  CustomModalFooter?: any;
  dynamicModalOpen?: boolean;
  onClose?: () => void;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<any>("");

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const classes = clsx(
    "form-control form-control-solid mb-3 mb-lg-0 p-4 text-start",
  );

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose && onClose();
    }
    setIsModalOpen(false);

    setSearchValue("");
  }, [onClose]);

  const handleButtonPress = (e: any) => {
    e.preventDefault();

    setIsModalOpen(true);
  };

  const handleSelectItem = useCallback(
    (item: CustomItemPress) => {
      if (item.id) {
        setSelectedItem && setSelectedItem(item);
      }
      withCloseAfterSelect && handleClose();
    },
    [handleClose, setSelectedItem, withCloseAfterSelect],
  );

  useEffect(() => {
    if (debouncedSearchValue !== null && debouncedSearchValue !== undefined) {
      const debouncedSearchValueToString = debouncedSearchValue?.toString();
      modalActions({ search: debouncedSearchValueToString });
    }
  }, [debouncedSearchValue, modalActions]);

  useEffect(() => {
    if (dynamicModalOpen !== undefined) {
      setIsModalOpen(dynamicModalOpen);
    }
  }, [dynamicModalOpen]);

  useEffect(() => {
    return () => {
      handleClose && handleClose();
    };
  }, [handleClose]);

  return (
    <>
      <ModalWrapper
        handleClose={handleClose}
        show={isModalOpen}
        title={modalTitle}>
        <TextInput
          name="searchProduct"
          placeholder={searchPlaceholder}
          value={searchValue}
          onChange={setSearchValue}
        />
        {selectedItem?.id && (
          <CustomListItem
            withIcon
            backgroundColor={"success"}
            infoText={{ text: "Selectat" }}
            item={selectedItem}
          />
        )}
        {items?.map((item) => {
          return (
            <CustomListItem
              key={item.id}
              withIcon
              item={item}
              backgroundColor={item.backgroundColor}
              onClick={handleSelectItem}
            />
          );
        })}
        {CustomModalFooter && (
          <CustomModalFooter items={items} handleClose={handleClose} />
        )}
      </ModalWrapper>
      {!CustomOpenModalComponent && (
        <div className="mb-7 w-100">
          {label && (
            <Text
              variant="body1"
              isBold
              gutterBottom
              className="form-label fs-6 fw-bolder text-dark">
              {label}
            </Text>
          )}
          <button className={classes} onClick={handleButtonPress}>
            {selectedItem?.label ? selectedItem.label : "Selecteaza"}
          </button>
        </div>
      )}
      {CustomOpenModalComponent && (
        <CustomOpenModalComponent handleButtonPress={handleButtonPress} />
      )}
    </>
  );
}

export default ModalList;
