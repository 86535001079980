import React from "react";
import LottieAnimation from "../helpers/LottieAnimation";

function LoadingPage() {
  return (
    <div
      style={{ height: "80vh" }}
      className="d-flex justify-content-center align-items-center">
      <LottieAnimation type="loading" height="40%" width="40%" />
    </div>
  );
}

export default LoadingPage;
