import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import ContentCard from "../../../components/misc/ContentCard";
import useRoutesHelper from "../../../hooks/useRoutesHelper";

import {
  getScheduleWorkingPointsDetails,
  getWorkingRoutes,
  postCreateOrUpdateWorkingPoint,
} from "../../../redux/api/expedition.api";
import { resetScheduleWorkingPointsDetails } from "../../../redux/reducer/expedition.reducer";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../helpers/apiHelper";

import ConfigWorkingDays, {
  AddAllDaysItemScheduleInterface,
} from "../components/ConfigWorkingDays";
import Dropdown, { DropdownValue } from "../../../components/misc/Dropdown";
import FormButtons from "../../../components/misc/FormButtons";
import { Formik } from "formik";
import TextInput from "../../../components/misc/TextInput";
import countiesAndLocalities from "../../../constants/judete.json";

interface initialValuesInterface {
  selectedWorkingRoute: DropdownValue | null;
  adresa: string;
  denumire: string;
  localitate: string;
  judet: string;
  cod_postal: string;
  pers_contact: string;
  tel_contact: string;
}

const initialValues: initialValuesInterface = {
  selectedWorkingRoute: null,
  adresa: "",
  denumire: "",
  judet: "-",
  localitate: "-",
  cod_postal: "",
  pers_contact: "",
  tel_contact: "",
};

const formikSchema = Yup.object({
  adresa: Yup.string().required("Denumirea este obligatorie"),
  denumire: Yup.string().required("Codul este obligatoriu"),
  judet: Yup.string().notOneOf(["-", ""], "Judetul este obligatoriu"),
  localitate: Yup.string().notOneOf(["-", ""], "Localitatea este obligatorie"),
  cod_postal: Yup.string().required("Codul postal este obligatoriu"),
  pers_contact: Yup.string().required("Persoana de contact este obligatorie"),
  tel_contact: Yup.string().required("Telefonul de contact este obligatoriu"),
});

function ExpeditionScheduleWorkingPointsOrganise({
  withUpdate,
}: {
  withUpdate?: boolean;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    routeParams: { id, routeId },
  } = useRoutesHelper();

  const [isSaving, setIsSaving] = useState(false);

  const [formikValues, setFormikValues] =
    useState<initialValuesInterface>(initialValues);

  const {
    expedition: {
      scheduleWorkingPointsDetails: {
        data: { days: workingPointDays, pctLucru: workingPointDetails },
        isLoading: isLoadingScheduleWorkingPointsDetails,
      },
      scheduleWorkingRoutes: { data: scheduleWorkingRoutesData, isLoading },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const [selectedDays, setSelectedDays] = useState<
    AddAllDaysItemScheduleInterface[]
  >([]);

  useEffect(() => {
    dispatch(
      getWorkingRoutes({
        page: 1,
        perPage: 500,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getScheduleWorkingPointsDetails({ id: id }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    let defaultRoute = scheduleWorkingRoutesData?.data[0];

    if (defaultRoute) {
      setFormikValues((prev) => ({
        ...prev,
        selectedWorkingRoute: {
          label: defaultRoute.denumire,
          id: defaultRoute.id_ruta,
        },
      }));
    }

    if (withUpdate && routeId) {
      const selectedRoute = scheduleWorkingRoutesData.data.find(
        (route) => route.id_ruta.toString() === routeId.toString(),
      );

      if (selectedRoute) {
        defaultRoute = selectedRoute;
      }

      if (workingPointDetails) {
        setFormikValues((prev) => ({
          ...prev,
          adresa: workingPointDetails.adresa,
          denumire: workingPointDetails.denumire,
          judet: workingPointDetails.judet || "-",
          localitate: workingPointDetails.localitate || "-",
          cod_postal: workingPointDetails.cod_postal,
          pers_contact: workingPointDetails.pers_contact,
          tel_contact: workingPointDetails.tel_contact,
        }));
      }
    }
  }, [scheduleWorkingRoutesData, routeId, withUpdate, workingPointDetails]);

  useEffect(() => {
    return () => {
      dispatch(resetScheduleWorkingPointsDetails());
    };
  }, [dispatch]);

  const onlyActiveDays = selectedDays.filter((item) => item.isActive);

  const handleSave = async (value: initialValuesInterface) => {
    const workingRoute = scheduleWorkingRoutesData?.data.find(
      (route) => route.id_ruta === value.selectedWorkingRoute?.id,
    );

    if (!workingRoute) {
      return;
    }

    setIsSaving(true);

    const resultAction = await dispatch(
      postCreateOrUpdateWorkingPoint({
        ...value,
        activeWorkingDays: onlyActiveDays,
        id: id,
        selectedWorkingRoute: workingRoute,
      }),
    );

    if (
      checkEndpointStatus(resultAction, postCreateOrUpdateWorkingPoint) !==
      endpointStatus.pending
    ) {
      setIsSaving(false);
    }

    if (
      checkEndpointStatus(resultAction, postCreateOrUpdateWorkingPoint) ===
      endpointStatus.fulfilled
    ) {
      navigate(-1);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const getLocaltiesForSelectedCounty = (county?: {
    id: string;
    label: string;
    localitati: {
      nume: string;
      simplu?: string;
      comuna?: string;
    }[];
  }) => {
    if (!county) {
      return [];
    }

    return (
      county?.localitati.map((locality: { nume: string }) => ({
        label: locality.nume,
        id: locality.nume,
      })) || []
    );
  };

  const countiesFormatted = useMemo(() => {
    return countiesAndLocalities.judete.map((county) => ({
      id: county.id,
      label: county.id,
      localitati: county.localitati,
    }));
  }, []);

  const scheduleWorkingRoutesDataFormatted =
    scheduleWorkingRoutesData?.data.map((route) => ({
      id: route.id_ruta,
      label: route.denumire,
    }));

  return (
    <ContentCard
      isLoading={isLoading || isLoadingScheduleWorkingPointsDetails}
      cardTitle={
        withUpdate
          ? `Organizare punctul de lucru ${id}`
          : "Creaza punct de lucru"
      }>
      <Formik
        enableReinitialize
        initialValues={formikValues}
        validationSchema={formikSchema}
        onSubmit={handleSave}>
        {({
          errors,
          touched,
          values,
          isSubmitting,
          getFieldProps,
          handleSubmit,
          setFieldValue,
        }) => {
          const { selectedWorkingRoute } = values;

          const selectedCounty = countiesFormatted.find(
            (county) => county.id === values.judet,
          );

          const localitiesFormatted =
            getLocaltiesForSelectedCounty(selectedCounty);

          const selectedLocality = localitiesFormatted?.find(
            (locality: { id: string }) => locality.id === values.localitate,
          );

          return (
            <form
              className="form w-100"
              onSubmit={handleSubmit}
              noValidate
              id="kt_login_signin_form">
              <TextInput
                label="Adresa"
                name="adresa"
                error={errors["adresa"]}
                touched={touched["adresa"]}
                inputProps={getFieldProps("adresa")}
              />
              <TextInput
                label="Punct de livrare"
                name="denumire"
                error={errors["denumire"]}
                touched={touched["denumire"]}
                inputProps={getFieldProps("denumire")}
              />
              <Dropdown
                title="Judet"
                data={countiesFormatted}
                singleValue={
                  selectedCounty || {
                    label: "-",
                    id: "-",
                  }
                }
                setSingleValue={(value) => setFieldValue("judet", value?.id)}
                error={errors["judet"]}
                touched={touched["judet"]}
              />
              {localitiesFormatted?.length > 0 && (
                <Dropdown
                  title="Localitate"
                  data={localitiesFormatted}
                  singleValue={
                    selectedLocality || {
                      label: "-",
                      id: "-",
                    }
                  }
                  setSingleValue={(value) =>
                    setFieldValue("localitate", value?.id)
                  }
                  error={errors["localitate"]}
                  touched={touched["localitate"]}
                />
              )}

              <TextInput
                type="number"
                label="Cod postal"
                name="cod_postal"
                error={errors["cod_postal"]}
                touched={touched["cod_postal"]}
                inputProps={getFieldProps("cod_postal")}
              />
              <TextInput
                label="Persoana de contact"
                name="pers_contact"
                error={errors["pers_contact"]}
                touched={touched["pers_contact"]}
                inputProps={getFieldProps("pers_contact")}
              />
              <TextInput
                type="tel"
                label="Telefon de contact"
                name="tel_contact"
                error={errors["tel_contact"]}
                touched={touched["tel_contact"]}
                inputProps={getFieldProps("tel_contact")}
              />

              <Dropdown
                className="mb-4 me-2"
                title="Selecteaza ruta"
                data={scheduleWorkingRoutesDataFormatted}
                singleValue={selectedWorkingRoute}
                setSingleValue={(value) => {
                  setFieldValue("selectedWorkingRoute", value);
                }}
              />
              <ConfigWorkingDays
                withoutTime
                alreadyCheckedDays={workingPointDays}
                setSelectedDays={setSelectedDays}
              />

              <FormButtons
                goBackLabel="Anuleaza"
                goNextLabel="Salveaza"
                handleGoBack={handleCancel}
                nextButtonDisabled={isSubmitting || onlyActiveDays.length === 0}
                nextButtonLoading={isSaving}
              />
            </form>
          );
        }}
      </Formik>
    </ContentCard>
  );
}

export default ExpeditionScheduleWorkingPointsOrganise;
