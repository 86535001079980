import React from "react";
import { KTSVG } from "../../../helpers/KTSVG";
import Text from "../../../styleguide/Text";
import SelectableChecker from "./SelectableChecker";

export interface OnSelectableItemClick {
  value?: number;
  items?: any[];
  checked?: boolean;
  name?: string;
}

interface SelectableItem {
  type?: "checkbox" | "radio";
  name: string;
  icon?: {
    name: string;
    backgroundColor?:
      | "light-primary"
      | "light-secondary"
      | "light-info"
      | "light-success"
      | "light-warning"
      | "light-danger"
      | "light-light"
      | "light-dark";
    color?:
      | "primary"
      | "secondary"
      | "info"
      | "success"
      | "warning"
      | "danger"
      | "light"
      | "dark";
  };
  title?: string;
  subtitle?: string;
  value?: number;
  checked?: boolean;
  onClick?: (value: OnSelectableItemClick) => void;
  items?: any[];
  className?: string;
}

export const getNewItemsWithCategory = (
  name: string,
  categoryItems: any[],
  newRadioItems: any[],
) => {
  const newItems = categoryItems.map((problemsItem: any) => {
    if (problemsItem.type === name && newRadioItems) {
      return {
        ...problemsItem,
        reasons: newRadioItems,
      };
    } else {
      return problemsItem;
    }
  });

  return newItems;
};

function SelectableItem({
  type = "checkbox",
  name,
  icon,
  title,
  subtitle,
  value,
  checked = false,
  onClick,
  items,
  className,
}: SelectableItem) {
  const getNewRadioItems = () => {
    if (!items || items?.length === 0) {
      return [];
    }
    const newItems = items.map((newItem) => {
      if (newItem.value === value) {
        return {
          ...newItem,
          checked: true,
        };
      } else {
        return {
          ...newItem,
          checked: false,
        };
      }
    });

    return newItems;
  };

  const getNewCheckboxItems = () => {
    if (!items || items?.length === 0) {
      return [];
    }
    const newItems = items.map((newItem) => {
      if (newItem.value === value) {
        return {
          ...newItem,
          checked: !newItem.checked,
        };
      } else {
        return newItem;
      }
    });

    return newItems;
  };

  const onSelectableClick = () => {
    const itemsFormated =
      type === "radio" ? getNewRadioItems() : getNewCheckboxItems();
    onClick && onClick({ value, checked, name, items: itemsFormated });
  };

  return (
    <label
      className={`d-flex align-items-center justify-content-between mb-6 cursor-pointer ${
        className ?? ""
      }`}>
      <span className="d-flex align-items-center me-2">
        {icon && (
          <span className="symbol symbol-50px me-6">
            <span
              className={`symbol-label bg-${
                icon.backgroundColor ?? "light-primary"
              }`}>
              <KTSVG
                path={icon.name}
                className={`svg-icon-1 svg-icon-${icon.color ?? "primary"}`}
              />
            </span>
          </span>
        )}

        <Text
          variant="subtitle1"
          component="span"
          className="fw-bolder fs-6"
          gutterBottom={!!subtitle}>
          {title ?? value}
        </Text>
        {subtitle && (
          <Text variant="body1" component="span" className="fs-7 text-muted">
            {subtitle}
          </Text>
        )}
      </span>
      <SelectableChecker
        type={type}
        name={name}
        value={value}
        checked={checked}
        onChange={onSelectableClick}
      />
    </label>
  );
}

export default SelectableItem;
