import React from "react";
import { CircularProgress } from "@mui/material";

function LoadingIndicator({
  size = 25,
  color = "primary",
}: {
  size?: number;
  color?: "primary" | "secondary" | "inherit" | undefined;
}) {
  return <CircularProgress size={size} color={color} />;
}

export default LoadingIndicator;
