import { useAppSelector } from "../redux/hooks";

function usePermissions() {
  const {
    user: { userRoutesAccess },
  } = useAppSelector((state) => ({
    user: state.user,
  }));

  const isAuthorized = (id: string) => {
    return userRoutesAccess.includes(id);
  };

  return {
    isAuthorized,
  };
}

export default usePermissions;
