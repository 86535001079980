import { createSlice } from "@reduxjs/toolkit";

import {
  FleetAllDocumentsInterface,
  FleetDataInterface,
  FleetDocumentsDataInterface,
  FleetDocumentsInterface,
  FleetFeatureDocumentsDataInterface,
  FleetFeatureDocumentsInterface,
  FleetFeaturesDataInterface,
  FleetFeaturesInterface,
  FleetInterface,
  FleetTypeInterface,
} from "../interfaces/fleet.interface";
import {
  fetchAllFleetDocs,
  fetchFleet,
  fetchFleetFeatureDocuments,
  fetchFleetFeatures,
  fetchFleetTypes,
  fetchShowAllDocTypes,
  fetchShowDocs,
} from "../api/fleet.api";

export interface StateFleetInterface {
  fleetData: FleetInterface;
  fleetCarData: FleetDataInterface | null;
  fleetFeatures: FleetFeaturesInterface;
  fleetFeatureData: FleetFeaturesDataInterface | null;
  fleetFeatureDocuments: FleetFeatureDocumentsInterface;
  fleetFeatureDocumentData: FleetFeatureDocumentsDataInterface | null;
  fleetDocuments: FleetDocumentsInterface;
  fleetDocumentData: FleetDocumentsDataInterface | null;
  fleetTypeData: FleetTypeInterface;
  fleetTypeDataAllDocs: FleetTypeInterface;
  fleetAllDocuments: FleetAllDocumentsInterface;
}

export const defaultStateFleet: StateFleetInterface = {
  fleetData: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  fleetCarData: null,
  fleetFeatures: {
    data: [],
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  fleetFeatureData: null,
  fleetFeatureDocuments: {
    data: [],
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  fleetFeatureDocumentData: null,
  fleetDocuments: {
    data: [],
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  fleetDocumentData: null,
  fleetTypeData: {
    data: [],
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  fleetTypeDataAllDocs: {
    data: [],
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  fleetAllDocuments: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
};

const fleet = createSlice({
  name: "fleet",
  initialState: defaultStateFleet,
  reducers: {
    setFleetCarData: (
      state,
      action: {
        payload: FleetDataInterface;
      },
    ) => {
      return {
        ...state,
        fleetCarData: action.payload,
      };
    },
    clearFleetCarData: (state) => {
      return {
        ...state,
        fleetCarData: null,
      };
    },
    clearFleetFeatureDocuments: (state) => {
      return {
        ...state,
        fleetFeatureDocuments: {
          ...state.fleetFeatureDocuments,
          data: [],
        },
      };
    },
    clearFleetFeatures: (state) => {
      return {
        ...state,
        fleetFeatures: {
          ...state.fleetFeatures,
          data: [],
        },
      };
    },
    setFleetFeatureData: (
      state,
      action: {
        payload: FleetFeaturesDataInterface;
      },
    ) => {
      return {
        ...state,
        fleetFeatureData: action.payload,
      };
    },
    clearFleetFeatureData: (state) => {
      return {
        ...state,
        fleetFeatureData: null,
      };
    },
    setFleetFeatureDocumentData: (
      state,
      action: { payload: FleetFeatureDocumentsDataInterface },
    ) => {
      return {
        ...state,
        fleetFeatureDocumentData: action.payload,
      };
    },
    clearFleetFeatureDocumentData: (state) => {
      return {
        ...state,
        fleetFeatureDocumentData: null,
      };
    },
    clearFleetDocuments: (state) => {
      return {
        ...state,
        fleetDocuments: {
          ...state.fleetDocuments,
          data: [],
        },
      };
    },
    setFleetDocumentData: (
      state,
      action: {
        payload: FleetDocumentsDataInterface;
      },
    ) => {
      return {
        ...state,
        fleetDocumentData: action.payload,
      };
    },
    clearFleetDocumentData: (state) => {
      return {
        ...state,
        fleetDocumentData: null,
      };
    },
    clearFleetTypeData: (state) => {
      return {
        ...state,
        fleetTypeData: defaultStateFleet.fleetTypeData,
      };
    },
  },
  extraReducers: (builder) => {
    // fleet start
    builder.addCase(fetchFleet.pending, (state) => {
      return {
        ...state,
        fleetData: {
          ...state.fleetData,
          isLoading: true,
          isError: false,
          errorMessage: "",
        },
      };
    });
    builder.addCase(fetchFleet.rejected, (state, action: any) => {
      return {
        ...state,
        fleetData: {
          ...state.fleetData,
          isLoading: false,
          isError: true,
          errorMessage: action.payload.message,
        },
      };
    });
    builder.addCase(fetchFleet.fulfilled, (state, action) => {
      return {
        ...state,
        fleetData: {
          ...state.fleetData,
          data: action.payload,
          isLoading: false,
          isError: false,
          errorMessage: "",
        },
      };
    });
    // fleet end

    // fleet features start
    builder.addCase(fetchFleetFeatures.pending, (state) => {
      return {
        ...state,
        fleetFeatures: {
          ...state.fleetFeatures,
          isLoading: true,
          isError: false,
          errorMessage: "",
        },
      };
    });
    builder.addCase(fetchFleetFeatures.rejected, (state, action: any) => {
      return {
        ...state,
        fleetFeatures: {
          ...state.fleetFeatures,
          isLoading: false,
          isError: true,
          errorMessage: action.payload.message,
        },
      };
    });
    builder.addCase(fetchFleetFeatures.fulfilled, (state, action) => {
      return {
        ...state,
        fleetFeatures: {
          ...state.fleetFeatures,
          data: action.payload,
          isLoading: false,
          isError: false,
          errorMessage: "",
        },
      };
    });
    // fleet features end
    // fleet feature documents start
    builder.addCase(fetchFleetFeatureDocuments.pending, (state) => {
      return {
        ...state,
        fleetFeatureDocuments: {
          ...state.fleetFeatureDocuments,
          isLoading: true,
          isError: false,
          errorMessage: "",
        },
      };
    });
    builder.addCase(
      fetchFleetFeatureDocuments.rejected,
      (state, action: any) => {
        return {
          ...state,
          fleetFeatureDocuments: {
            ...state.fleetFeatureDocuments,
            isLoading: false,
            isError: true,
            errorMessage: action.payload.message,
          },
        };
      },
    );
    builder.addCase(fetchFleetFeatureDocuments.fulfilled, (state, action) => {
      return {
        ...state,
        fleetFeatureDocuments: {
          ...state.fleetFeatureDocuments,
          data: action.payload,
          isLoading: false,
          isError: false,
          errorMessage: "",
        },
      };
    });
    // fleet feature documents end
    // fleet documents start
    builder.addCase(fetchShowDocs.pending, (state) => {
      return {
        ...state,
        fleetDocuments: {
          ...state.fleetDocuments,
          isLoading: true,
          isError: false,
          errorMessage: "",
        },
      };
    });
    builder.addCase(fetchShowDocs.rejected, (state, action: any) => {
      return {
        ...state,
        fleetDocuments: {
          ...state.fleetDocuments,
          isLoading: false,
          isError: true,
          errorMessage: action.payload.message,
        },
      };
    });
    builder.addCase(fetchShowDocs.fulfilled, (state, action) => {
      return {
        ...state,
        fleetDocuments: {
          ...state.fleetDocuments,
          data: action.payload,
          isLoading: false,
          isError: false,
          errorMessage: "",
        },
      };
    });
    // fleet documents end
    // fleet type start
    builder.addCase(fetchFleetTypes.pending, (state) => {
      return {
        ...state,
        fleetTypeData: {
          ...defaultStateFleet.fleetTypeData,
          isLoading: true,
        },
      };
    });
    builder.addCase(fetchFleetTypes.rejected, (state, action: any) => {
      return {
        ...state,
        fleetTypeData: {
          ...defaultStateFleet.fleetTypeData,
          isError: true,
          errorMessage: action.payload.message,
        },
      };
    });
    builder.addCase(
      fetchFleetTypes.fulfilled,
      (
        state,
        action: {
          payload: {
            id: string;
            label: string;
            camp_tabela: string;
            denumire: string;
            id_denumiri: number | string;
            tabela: string;
            valoare: number | string;
          }[];
        },
      ) => {
        return {
          ...state,
          fleetTypeData: {
            ...defaultStateFleet.fleetTypeData,
            data: action.payload?.map((item) => ({
              ...item,
              label: item.denumire,
              id: item.valoare,
            })),
          },
        };
      },
    );
    // fleet type end

    // fleet type all docs start
    builder.addCase(fetchShowAllDocTypes.pending, (state) => {
      return {
        ...state,
        fleetTypeDataAllDocs: {
          ...defaultStateFleet.fleetTypeDataAllDocs,
          isLoading: true,
        },
      };
    });
    builder.addCase(fetchShowAllDocTypes.rejected, (state, action: any) => {
      return {
        ...state,
        fleetTypeDataAllDocs: {
          ...defaultStateFleet.fleetTypeDataAllDocs,
          isError: true,
          errorMessage: action.payload.message,
        },
      };
    });
    builder.addCase(
      fetchShowAllDocTypes.fulfilled,
      (
        state,
        action: {
          payload: {
            id: string;
            label: string;
            camp_tabela: string;
            denumire: string;
            id_denumiri: number | string;
            tabela: string;
            valoare: number | string;
          }[];
        },
      ) => {
        return {
          ...state,
          fleetTypeDataAllDocs: {
            ...defaultStateFleet.fleetTypeDataAllDocs,
            data: action.payload?.map((item) => ({
              ...item,
              label: item.denumire,
              id: item.valoare,
            })),
          },
        };
      },
    );
    // fleet type all docs end

    // fleet all documents start
    builder.addCase(fetchAllFleetDocs.pending, (state) => {
      return {
        ...state,
        fleetAllDocuments: {
          ...defaultStateFleet.fleetAllDocuments,
          isLoading: true,
          isError: false,
          errorMessage: "",
        },
      };
    });
    builder.addCase(fetchAllFleetDocs.rejected, (state, action: any) => {
      return {
        ...state,
        fleetAllDocuments: {
          ...defaultStateFleet.fleetAllDocuments,
          isLoading: false,
          isError: true,
          errorMessage: action.payload.message,
        },
      };
    });
    builder.addCase(fetchAllFleetDocs.fulfilled, (state, action) => {
      return {
        ...state,
        fleetAllDocuments: {
          ...state.fleetAllDocuments,
          data: action.payload,
          isLoading: false,
          isError: false,
          errorMessage: "",
        },
      };
    });
    // fleet all documents end
  },
});

export const {
  setFleetCarData,
  clearFleetCarData,
  setFleetFeatureData,
  clearFleetFeatureData,
  setFleetFeatureDocumentData,
  clearFleetFeatureDocumentData,
  setFleetDocumentData,
  clearFleetDocumentData,
  clearFleetTypeData,
  clearFleetFeatureDocuments,
  clearFleetFeatures,
  clearFleetDocuments,
} = fleet.actions;

export default fleet.reducer;
