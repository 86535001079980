import { Checkbox, TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import ContentCard from "../../../components/misc/ContentCard";
import Text from "../../../styleguide/Text";
import { getWeekDays } from "../../../utils/dateAndTime";

export interface AddAllDaysItemScheduleInterface {
  day: {
    id: number;
    name?: string;
  };
  startingHour?: Moment;
  startingHourString?: string;
  isActive?: boolean;
}

const weekDays = getWeekDays("ro");

const weekDaysSchedule = weekDays.map((day) => {
  return {
    day,
    isActive: false,
  };
});

const weekDaysWithSelectAll = [
  {
    day: {
      id: 10,
      name: "Toate zilele",
    },
    isActive: false,
  },
  ...weekDaysSchedule,
];

const weekDaysWithStartingHour = weekDaysWithSelectAll.map((item) => {
  return {
    ...item,
    startingHour: moment(),
    startingHourString: moment().format("HH:mm"),
  };
});

function ConfigWorkingDays({
  withoutTime,
  alreadyCheckedDays,
  setSelectedDays,
  FooterComponent,
  itemClassName,
  itemStyle,
}: {
  withoutTime?: boolean;
  alreadyCheckedDays?: AddAllDaysItemScheduleInterface[];
  setSelectedDays: React.Dispatch<
    React.SetStateAction<AddAllDaysItemScheduleInterface[]>
  >;
  FooterComponent?: React.ReactNode;
  itemClassName?: string;
  itemStyle?: React.CSSProperties;
}) {
  const [addAllDaysItemSchedule, setAddAllDaysItemSchedule] = useState<
    AddAllDaysItemScheduleInterface[]
  >(withoutTime ? weekDaysWithSelectAll : weekDaysWithStartingHour);

  useEffect(() => {
    if (alreadyCheckedDays && alreadyCheckedDays?.length > 0) {
      setAddAllDaysItemSchedule((prevState) => {
        return prevState.map((selectedItem) => {
          const alreadyCheckedDay = alreadyCheckedDays.find(
            (item) => item.day.id === selectedItem.day.id,
          );
          if (alreadyCheckedDay) {
            return {
              ...selectedItem,
              startingHour: alreadyCheckedDay.startingHourString
                ? moment(alreadyCheckedDay.startingHourString, "HH:mm")
                : undefined,
              startingHourString: alreadyCheckedDay.startingHourString,
              isActive: true,
            };
          }
          return selectedItem;
        });
      });
    }
  }, [alreadyCheckedDays]);

  useEffect(() => {
    if (addAllDaysItemSchedule.length) {
      setSelectedDays(addAllDaysItemSchedule);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addAllDaysItemSchedule]);

  const handleChange = (
    newValue: Moment | null | undefined,
    item: AddAllDaysItemScheduleInterface,
  ) => {
    if (newValue && !withoutTime) {
      setAddAllDaysItemSchedule((prevState) => {
        return prevState.map((selectedItem) => {
          if (selectedItem.day.id !== item.day.id) {
            return selectedItem;
          }
          return {
            ...selectedItem,
            startingHour: newValue,
            startingHourString: newValue.format("HH:mm"),
          };
        });
      });
    }
  };

  const handleCheckboxClick = (item: AddAllDaysItemScheduleInterface) => {
    const getIsActiveItems = (clickedItem: AddAllDaysItemScheduleInterface) => {
      if (clickedItem.day.id === 10) {
        return false;
      }

      if (clickedItem.day.id === item.day.id) {
        return !clickedItem.isActive;
      }
      return clickedItem.isActive;
    };

    setAddAllDaysItemSchedule((prevState) => {
      const isSelectAllItems = item.day.id === 10;
      if (isSelectAllItems) {
        return prevState.map((selectedItem) => {
          return {
            ...selectedItem,
            isActive:
              selectedItem.day.id === 10 ? !selectedItem.isActive : false,
          };
        });
      }

      return prevState.map((selectedItem) => {
        return {
          ...selectedItem,
          isActive: getIsActiveItems(selectedItem),
        };
      });
    });
  };

  return (
    <div>
      <ContentCard>
        <ul>
          {addAllDaysItemSchedule.map((item) => {
            return (
              <li
                key={item.day.id}
                className={`py-4 d-flex justify-content-between align-items-center ${
                  itemClassName ?? ""
                }`}
                style={itemStyle}>
                <div className="d-flex align-items-center">
                  <Checkbox
                    id={`checkbox-${item.day.id}`}
                    checked={item?.isActive || false}
                    tabIndex={-1}
                    disableRipple
                    style={{
                      transform: "scale(2)",
                    }}
                    onClick={() => handleCheckboxClick(item)}
                    disabled={false}
                    inputProps={{
                      "aria-labelledby": `enhanced-table-checkbox-${item.day.id}`,
                    }}
                  />
                  <label
                    htmlFor={`checkbox-${item.day.id}`}
                    style={{ width: 130 }}>
                    <Text className="ms-3">{item.day.name}</Text>
                  </label>
                </div>
                {!withoutTime && (
                  <TimePicker
                    label="Time"
                    // minutesStep={30}
                    // maxTime={moment("23:59", "HH:mm")}
                    ampmInClock={false}
                    ampm={false}
                    value={item.startingHour}
                    onChange={(e) => handleChange(e, item)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              </li>
            );
          })}
        </ul>
      </ContentCard>
      {FooterComponent && FooterComponent}
    </div>
  );
}

export default ConfigWorkingDays;
